import styled from "styled-components";

export const RelSection = styled.section`
    padding: 24px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    border-radius: 15px;
    background: var(--color-grayBlue);

    @media (max-width: 375px) {
        padding: 10px;
    }
`;


export const Grafics = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border-radius: 10px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly; /* Mantenha isso para alinhamento */
    gap: 24px;
    padding: 12px 0px 0px 0;
    flex-direction: row;
    flex-wrap: wrap;    
    
    >div{
        background: var(--color-white);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
    
    >div:last-child{
        grid-column: span 5;
    }
    
    >div:first-child{
        grid-column: span 3;
    }
    
    @media (max-width: 997px) {
        display: flex;
        flex-direction: column;

        >div{
            width: 100%;
        }
    }
    
    /* > div:first-child {
            flex: 1 0 0;

        @media (max-width: 1110px) {
            flex: 1 0 30%
        }

        @media (max-width: 997px) {
            flex: 0 0 100%
        }
    } */
    /* Mantenha o flex-wrap no GraficsContainer */
    /* > div {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        flex: 1; /* Para permitir que os gráficos ocupem espaço proporcional */
        /* max-width: 100%;
        min-width: 290px;

        @media (max-width: 1200px) {
            min-width: 320px;
        }

        @media (max-width: 997px) {
        flex-direction: row;
        flex-wrap: wrap;
        }
    }

    @media (max-width: 320px) {
        padding: 12px 0;
        box-shadow: none
    }  */
`;

export const Cardscontainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const DashboardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    width: 100%;
    height: 100%;
    gap: 12px;
    padding: 24px 24px 12px 24px;
    /* padding-bottom: 88px; */
    

    @media (min-width: 1400px) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
`;



export const Controler = styled.div`
    /* font-family: Poppins; */
    line-height: 24px;
    font-weight: 600;
    font-size: clamp(12px, 0.8vw, 24px); 
    padding-top: 12px;
    
    p{
        font-size: clamp(10px, 0.7vw, 12px); 
        color: #737791;
        padding-left: 6px;
    }
`

export const ControlerFilter = styled.div`
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;

    >div{
        display: flex;
        align-items: center;
        padding: 2px 6px;
        border: 1px solid #C3D3E2;
        border-radius: 5px;
        gap:6px;
        font-size: 12px;
        cursor: pointer;

    }
`
export const Dropdown = styled.div`
    position: absolute;
    margin-top: 26px;
    right: 0; 
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #C3D3E2;
    border-radius: 5px;
    z-index: 2;
    padding: 8px 0;
    width: 150px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-x: auto;
    cursor: pointer;
`;

export const DropdownItem = styled.div`
    width: 100%;
    padding: 8px 2px;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    transition: background-color 0.3s;
    z-index: 2;
    color: ${({ $isHighlight }) => ($isHighlight ? '#000' : '#333')};
    font-weight: ${({ $isHighlight }) => ($isHighlight ? 'bold' : 'normal')};

    &:hover {
        background-color: #f3f3f3;
    }
`;



export const DivControlerMaps = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 0 24px;

`

export const GraficsContainer = styled.div`
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap; 
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 24px;

    >div {
        flex: 1 1 260px; 
        background: var(--color-white);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 24px 0px 0px 0px;
        max-width: 100%;

        @media (max-width: 715px) {
        padding: 24px 12px 24px 12px;
    }

    }

    h4 {
        color: var(--colot-text-title);
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 12px;
        padding-left: 24px;
    }
`;




export const GraficsItem = styled.div`
    margin-bottom: 12px;

    >div{
        padding-top: 0px;
        min-height: 0;
    }

    /* Comportamento específico em telas menores */
    @media (max-width: 997px) {
        max-height: 450px;
    }

    @media (max-width: 600px) {
        width: 100%; /* Garante que cada item ocupe a largura total quando em tela pequena */
        margin-bottom: 20px; /* Adiciona um espaço entre os itens */
    }

`;


export const Maps = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    

    @media (max-width: 963px) {
        width: 100% !important;
    }
    
`;

export const ContainerAside = styled.aside`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6px;
    align-items: center;
    justify-items: center;
    max-width: 100%;
    width: 100%;

    h4{
        color: var(--colot-text-title);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }
    
    @media(max-width: 1100px){
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 24px;
        
        }
        
        >div{
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 12px;
        padding-top: 24px;
        border-radius: 10px;
        background: var(--color-white-variante);

        @media (max-width: 320px) {
        box-shadow: none
        }
    }
`;

export const ContainerStatusMetrix = styled.div`

    h4{
        cursor: pointer;
    }

    @media (max-width: 1300px) {
        min-width: 250px;
        max-width: 100%;
    }
    @media (max-width: 1100px) {
        max-width: 100%;
    }    
`


export const ContainerBarChart = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 24px 12px 24px 12px;
    border-radius: 10px;
    background: var(--color-white-variante);

    h4{
        color: var(--colot-text-title);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }


    @media (max-width: 320px) {
        box-shadow: none
    }
`;

export const ContainerMidBarChart = styled.div`
        display: flex;
        flex-direction: row; /* Elementos lado a lado */
        flex-wrap: wrap;
        height: auto;
        align-items: stretch; /* Garante que os itens tenham a mesma altura */
        justify-content: center;
        gap: 30px;
        max-width: 100%;
        min-width: 100%;
    
    >div{
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: space-between; 
        gap: 12px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 24px 12px 0px 0px; 
        border-radius: 10px;
        background: var(--color-white-variante);
        width: 100%;
        min-width: 345px;
        max-width: 100%;

        @media (max-width: 715px) {
        padding: 24px 12px 24px 12px;
    }
    @media (max-width: 400px) {
        min-width: 245px;
    }
    }

    h4{
        color: var(--colot-text-title);
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 12px;
        padding-left: 24px;
    }
`;



export const SelectContainer =styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    height: 36px;
    margin-bottom: 12px;

    @media(max-width: 580px){
        max-width: 280px;
    }
`

export const Select = styled.select`
    text-align: center;
    padding: 6px;
    font-size: .75rem;
    border-radius: 20px;
    border: 1px solid var( --color-text-blue);;
    background-color: #fff;
    color: var( --color-text-blue);
    cursor: pointer;
    appearance: none; /* Oculta a seta padrão */
    -webkit-appearance: none; /* Para Safari */
    -moz-appearance: none; /* Para Firefox */

    &:focus {
        outline: none;
        border-color: #ccc;
    }

`;

export const Title = styled.div`
    height: 36px;
    margin-bottom: 12px;
`


export const Option = styled.option`
    background-color: #fff;
    color: #333;

    &:hover,
    &:focus {
        background-color: none;
        border-color: none;
    }

    @media(max-width: 405px){
        font-size: 9px;
    }
`;

export const Navigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    appearance: none; /* Remove a aparência padrão do select */
    border-radius: 50%; /* Círculo */
    border: 2px solid #afabf8; /* Borda do círculo */
    background-color: #ffffff; /* Cor de fundo */
    font-size: 16px; /* Tamanho da fonte */
    color: #333; /* Cor do texto */
    padding: 20px; /* Espaçamento interno */
    cursor: pointer;
    outline: none; /* Remove o contorno */
    transition: border 0.3s;
    width: 20px; /* Largura do círculo */
    height: 20px; /* Altura do círculo */
    right: 0;
    top: 0;
`;

export const ArrowButton = styled.button`
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    margin: 0 10px;
    transition: color 0.3s;
    padding: 15px;
    color: #afabf8; /* Cor ao passar o mouse */
`;


export const SearchContainer = styled.form`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    padding-bottom: 12px;

    button{
        max-width: 9.375rem; /* 150px */
    }
`;

export const InputBody = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3125rem; /* 5px */
    width: 100%;
    border-radius: 1.25rem; /* 20px */
    background: var(--color-grayBlue);
    cursor: text;

    svg{
        font-size: 1rem; /* 16px */
        margin-left: 0.625rem; /* 10px */
    }

    input{
        border-radius: 1.25rem; /* 20px */
        background-color: var(--color-grayBlue);
    }
`;