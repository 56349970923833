import React from "react";
import * as C from './LineChartComponentStyle';

const ChartControls = ({ 
  viewMode, 
  selectedYear, 
  availableYears, // Nova prop: lista de anos disponíveis
  onChangeViewMode, 
  onChangeYear 
}) => {
  return (
    <C.ControlContainer>
      {viewMode === "monthly" && (
        <C.Select 
          value={selectedYear} 
          onChange={(e) => onChangeYear(Number(e.target.value))}
        >
          {availableYears.map((year) => (
            <C.Option key={year} value={year}>
              {year}
            </C.Option>
          ))}
        </C.Select>
      )}
      <C.Button onClick={() => onChangeViewMode("monthly")}>
        Mensal
      </C.Button>
      <C.Button onClick={() => onChangeViewMode("yearly")}>
        Anual
      </C.Button>
    </C.ControlContainer>
  );
};

export default ChartControls;