import styled from "styled-components";


export const MapContainer = styled.div.attrs(props => ({
    style: {
      background: props.background,
    },
  }))`
    height: 600px; 
    position: relative; 
    background-color: white;
    max-width: 100%;
    margin: 12px;
    overflow: hidden;
    z-index: 0;

    @media(max-width: 580px){
        width: 90%;
    }
     
    .leaflet-control-attribution {
        display: none; 
    }

    .leaflet-interactive:focus {
        outline: none; 
    }

    .leaflet-interactive {
        pointer-events: auto !important; 
    }

    .leaflet-tooltip {
        display: block !important; 
        visibility: visible !important;
    }

    .leaflet-tooltip {
        position: absolute;
        top: -5%;
        left: 0;
        padding: 5px;
        background: #fff;
        border: 1px solid #8f8888;
        border-radius: 5px;
        cursor: default;
        transition: top 0.2s, left 0.2s;
        z-index: 1000;
    }

    .custom-label {
        font-size: clamp(8px, 0.6vw, 12px); 
        font-weight: bold;
        text-align: center;
        white-space: nowrap; 
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
        cursor: default;
    }


`;

