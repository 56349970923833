import React, { useRef, useLayoutEffect } from "react"; 
import L from "leaflet";
import * as C from "./MapaChatsStyles";
import geoMap from "../../assets/geo/geoBrasil.json";
import "../../styles/variables.css";

const MapChart = ({ estados, onClickState, selectedState, setSelectedState, onTitle }) => {
  const mapRef = useRef(null);
  const geojsonLayerRef = useRef(null);
  const labelsLayerRef = useRef(null);

  const processEstados = (estados) => {
    const processedEstados = {};

    estados.forEach(({ nome, valor, monetario }) => {
      const estadosSeparados = nome.split(" e ").map((estado) => estado.trim());

      estadosSeparados.forEach((estado) => {
        if (!processedEstados[estado]) {
          processedEstados[estado] = { valor, monetario, nomeCompleto: nome }; // Salva o valor e nome completo
        } else {
          // Mantém o maior valor e o nome completo original
          processedEstados[estado] = {
            monetario: Math.max(processedEstados[estado].monetario, monetario),
            valor: Math.max(processedEstados[estado].valor, valor),
            nomeCompleto: nome,
          };
        }
      });
    });

    return processedEstados;
  };

  useLayoutEffect(() => {
    const mapContainer = document.getElementById("map");

    const mapOptions = {
      scrollWheelZoom: false,
      zoomControl: false,
      doubleClickZoom: false,
      dragging: false,
    };

    // Inicializa o mapa
    const map = L.map(mapContainer, mapOptions);
    mapRef.current = map;

    // Remove a camada anterior para evitar vazamento de memória
    if (geojsonLayerRef.current) {
      map.removeLayer(geojsonLayerRef.current);
    }

    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
      };
    };

    const rgbToHex = (r, g, b) =>
      `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

    const interpolateColor = (value, maxValue) => {
      const minValue = 0; // Assumindo que o valor mínimo é zero
      const normalizedValue = (value - minValue) / (maxValue - minValue);
      const clampedValue = Math.min(Math.max(normalizedValue, 0), 1);

      const colorMin = hexToRgb("#ffffff"); // Branco
      const colorMax = hexToRgb("#000cff"); // Azul mais forte

      const r = Math.round(colorMin.r + (colorMax.r - colorMin.r) * clampedValue);
      const g = Math.round(colorMin.g + (colorMax.g - colorMin.g) * clampedValue);
      const b = Math.round(colorMin.b + (colorMax.b - colorMin.b) * clampedValue);

      return rgbToHex(r, g, b);
    };

    const processedEstados = processEstados(estados);

    const maxValor = Math.max(
      ...Object.values(processedEstados).map((estado) => estado.valor)
    );

    // Criação de nova camada GeoJSON
    geojsonLayerRef.current = L.geoJSON(geoMap, {
      style: (feature) => {
        const estadoNome = feature.properties.Estado;
        const estadoData = processedEstados[estadoNome] || { valor: 0, monetario: 0 };

        const fillColor = selectedState
          ? (selectedState === "Mato Grosso" || selectedState === "Mato Grosso do Sul")
            ? (estadoNome === selectedState ? "#000cff" : "#dddddd") // Comparação exata para Mato Grosso ou Mato Grosso do Sul
            : selectedState.includes(estadoNome)
            ? "#000cff"
            : "#dddddd"
          : interpolateColor(estadoData.valor, maxValor);

        return {
          color: "#8f8888",
          weight: 0.5,
          fillColor: fillColor,
          fillOpacity: 0.5,
        };
      },
      onEachFeature: (feature, layer) => {
        const estadoNome = feature.properties.Estado;
        const estadoData = processedEstados[estadoNome] || { valor: 0, nomeCompleto: estadoNome, monetario: 0 };

        const valorFormatado = new Intl.NumberFormat("pt-BR").format(estadoData.valor);

        const tooltipTitle = onTitle ? onTitle : "Processos";

        layer.bindTooltip(
            `<strong>${estadoData.nomeCompleto}</strong><br/>${tooltipTitle}: ${valorFormatado}`,
            {
                permanent: false,
                direction: "auto",
                offset: [0, 0],
            }
        );

        layer.on({
          mouseover: (e) => {
            const targetLayer = e.target;
            targetLayer.setStyle({ weight: 1 });
            targetLayer.openTooltip();
          },
          mouseout: (e) => {
            const targetLayer = e.target;
            targetLayer.setStyle({ weight: 0.5 });
            targetLayer.closeTooltip();
          },
          click: () => {
            if (!onClickState) return;
            setSelectedState(estadoData.nomeCompleto);
            onClickState(estadoData.nomeCompleto);

            const bounds = layer.getBounds();
            if (bounds) {
              map.fitBounds(bounds);
            }
          },
        });
      },
    }).addTo(map);

    // Limpa e cria novos rótulos para valores
    if (labelsLayerRef.current) {
      labelsLayerRef.current.clearLayers();
    } else {
      labelsLayerRef.current = L.layerGroup();
    }

    const formatWithDecimals = (num) => {
      let formatted = num.toFixed(0).replace('.', ',');  
      if (formatted.endsWith(',00')) {
          formatted = formatted.slice(0, -3);  
      } else if (formatted.endsWith('0')) {
          formatted = formatted.slice(0, -1); 
      }
      return formatted;
  };
  
  const formatValue = (numericValue, isCurrency = false) => {
      let formattedValue;
  
      // Verifica se o valor é monetário
      if (numericValue >= 1e18) {
          formattedValue = formatWithDecimals(numericValue / 1e18) + ' Qi'; // Quintilhão
      } else if (numericValue >= 1e15) {
          formattedValue = formatWithDecimals(numericValue / 1e15) + ' Qa'; // Quadrilhão
      } else if (numericValue >= 1e12) {
          formattedValue = formatWithDecimals(numericValue / 1e12) + ' Tri'; // Trilhão
      } else if (numericValue >= 1e9) {
          formattedValue = formatWithDecimals(numericValue / 1e9) + ' Bi'; // Bilhão
      } else if (numericValue >= 1e6) {
          formattedValue = formatWithDecimals(numericValue / 1e6) + ' M'; // Milhão
      } else {
          formattedValue = numericValue.toLocaleString('pt-BR');
      }
  
      // Se for um valor monetário, adiciona o prefixo "R$"
      return isCurrency ? `R$ ${formattedValue}` : formattedValue;
  };

  // Inicializamos gruposExibidos fora do loop para garantir que seja acessível por toda a lógica.
  const gruposExibidos = new Set();

  

  geojsonLayerRef.current.eachLayer((layer) => {
    const estadoNome = layer.feature.properties.Estado;
    const estadoData = processedEstados[estadoNome];
  
    // Define os grupos manualmente
    const grupos = {
      "Rio de Janeiro": "Grupo 1",
      "São Paulo": "Grupo 2",
      "Minas Gerais": "Grupo 3",
      "Rio Grande do Sul": "Grupo 4",
      "Bahia": "Grupo 5",
      "Pernambuco": "Grupo 6",
      "Ceará": "Grupo 7",
      "Pará": "Grupo 8",
      "Amapá": "Grupo 8",
      "Paraná": "Grupo 9",
      "Distrito Federal": "Grupo 10",
      "Tocantins": "Grupo 10",
      "Amazonas": "Grupo 11",
      "Roraima": "Grupo 11",
      "Santa Catarina": "Grupo 12",
      "Paraíba": "Grupo 13",
      "Rondônia": "Grupo 14",
      "Acre": "Grupo 14",
      "Maranhão": "Grupo 16",
      "Espírito Santo": "Grupo 17",
      "Goiás": "Grupo 18",
      "Alagoas": "Grupo 19",
      "Sergipe": "Grupo 20",
      "Rio Grande do Norte": "Grupo 21",
      "Piauí": "Grupo 22",
      "Mato Grosso": "Grupo 23",
      "Mato Grosso do Sul": "Grupo 24"
    };
  
    if (estadoData && estadoData.monetario && (!selectedState || estadoData.nomeCompleto === selectedState)) {
      const grupoNome = grupos[estadoNome] || estadoData.nomeCompleto;
  
      if (!gruposExibidos.has(grupoNome)) {
        const estadosNoGrupo = Object.keys(grupos).filter((estado) => grupos[estado] === grupoNome);
        
        let bounds = null;
        estadosNoGrupo.forEach((estado) => {
          geojsonLayerRef.current.eachLayer((layer) => {
            if (layer.feature.properties.Estado === estado) {
              const layerBounds = layer.getBounds();
              if (!bounds) {
                bounds = layerBounds;
              } else {
                bounds.extend(layerBounds);
              }
            }
          });
        });
  
        // Se bounds for definido corretamente
        if (bounds) {
          const valorFormatado = formatValue(estadoData.monetario, false);
          const label = L.divIcon({
            className: "custom-label",
            html: `<div>${valorFormatado}</div>`,
          });
  
          const center = bounds.getCenter();
          const labelMarker = L.marker(center, { icon: label });
  
          // Adiciona o evento de clique ao marcador
          labelMarker.on("click", () => {
            if (!onClickState) return;
            // Aciona a mesma lógica de clique que ocorre no estado
            setSelectedState(estadoData.nomeCompleto);
            onClickState(estadoData.nomeCompleto);
  
            // Ajusta o zoom para o grupo de estados
            map.fitBounds(bounds);
          });
  
          // Adiciona o marcador ao mapa
          labelsLayerRef.current.addLayer(labelMarker);
  
          // Marca o grupo como exibido
          gruposExibidos.add(grupoNome);
        }
      }
    }
  });
  

  
  
    labelsLayerRef.current.addTo(map);

    map.fitBounds(geojsonLayerRef.current.getBounds());

    const adjustMapZoom = () => {
      if (map) {
        map.fitBounds(geojsonLayerRef.current.getBounds());
      }
    };

    window.addEventListener("resize", adjustMapZoom);
    map.invalidateSize();

    // Cleanup function to remove event listener and map when the component is unmounted
    return () => {
      window.removeEventListener("resize", adjustMapZoom);
      if (geojsonLayerRef.current) {
        map.removeLayer(geojsonLayerRef.current);
      }
      if (labelsLayerRef.current) {
        labelsLayerRef.current.clearLayers();
      }
      map.remove(); // Remove the map instance to prevent memory leaks
    };
  }, [estados, selectedState, onClickState, setSelectedState, onTitle]);

  return <C.MapContainer id="map" />;
};

export default MapChart;
