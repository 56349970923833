import React from "react";
import * as C from "./NavbarStyles";
import "../../styles/variables.css";
import { TiHomeOutline, TiChartBarOutline, TiStarOutline, TiGroupOutline, TiThLargeOutline } from "react-icons/ti";
import logo from "../../assets/images/cropped-lg-anttecipe.jpeg"
import { BsGraphUp } from "react-icons/bs";
import { PiDownloadSimpleLight } from "react-icons/pi";
// import { GoFileDirectory } from "react-icons/go";

//TiFolderOpen, TiChartBarOutline, TiStarOutline, TiThLargeOutline, TiGroupOutline
// lista de navegação e controlador de components no home [12/08/2024]
// retorna o nome da aba que foi selecionado [12/08/2024]

const Navbar = ({ onNavLinkClick, userPermissions }) => {
    const hasPermission = (permissionId) => {
        return userPermissions && permissionId in userPermissions
    };


    return (
        <C.NavbarContainer>
            <C.NavLinks>
                {hasPermission(5) && (
                    <C.NavLink onClick={() => onNavLinkClick("Dashboard")}>
                        <BsGraphUp /> Dashboard
                    </C.NavLink>
                )}
                {hasPermission(1) && (
                    <C.NavLink onClick={() => onNavLinkClick("Consultas")}>
                        <TiHomeOutline /> Consultas
                    </C.NavLink>
                )}
                {hasPermission(2) && (
                    <C.NavLink onClick={() => onNavLinkClick("Usuários")}>
                        <TiGroupOutline /> Usuários
                    </C.NavLink>
                )}
                {hasPermission(4) && (
                    <C.NavLink onClick={() => onNavLinkClick("Empresas")}>
                        <TiChartBarOutline /> Empresas
                    </C.NavLink>
                )}
                {hasPermission(3) && (
                    <C.NavLink onClick={() => onNavLinkClick("Advogados")}>
                        <TiStarOutline /> Advogados
                    </C.NavLink>
                )}
                {hasPermission(7) && (
                    <C.NavLink onClick={() => onNavLinkClick("Análise AIA")}>
                        <TiThLargeOutline /> Análise AIA
                    </C.NavLink>
                )}
                {hasPermission(6) && (
                    <C.NavLink onClick={() => onNavLinkClick("Consulta em Lote")}>
                        <PiDownloadSimpleLight /> Consulta em Lote
                    </C.NavLink>
                )}
                {/* {hasPermission(6) && (
                    <C.NavLink onClick={() => onNavLinkClick("Mesa")}>
                        <GoFileDirectory /> Mesa
                    </C.NavLink>
                )}    */}
            </C.NavLinks>
            <C.ImgContainer>
                <img src={logo} alt="logo" />
            </C.ImgContainer>
        </C.NavbarContainer>
    );
};

export default Navbar;