import React, { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LabelList } from "recharts";
import * as C from "./DurationBarChartStyles";

const trts = [
  { nome: "TRT 1", estado: "Rio de Janeiro", sigla: "RJ" },
  { nome: "TRT 2", estado: "São Paulo", sigla: "SP" },
  { nome: "TRT 3", estado: "Minas Gerais", sigla: "MG" },
  { nome: "TRT 4", estado: "Rio Grande do Sul", sigla: "RS" },
  { nome: "TRT 5", estado: "Bahia", sigla: "BA" },
  { nome: "TRT 6", estado: "Pernambuco", sigla: "PE" },
  { nome: "TRT 7", estado: "Ceará", sigla: "CE" },
  { nome: "TRT 8", estado: "Pará e Amapá", sigla: "PA/AP" },
  { nome: "TRT 9", estado: "Paraná", sigla: "PR" },
  { nome: "TRT 10", estado: "Distrito Federal e Tocantins", sigla: "DF/TO" },
  { nome: "TRT 11", estado: "Amazonas e Roraima", sigla: "AM/RR" },
  { nome: "TRT 12", estado: "Santa Catarina", sigla: "SC" },
  { nome: "TRT 13", estado: "Paraíba", sigla: "PB" },
  { nome: "TRT 14", estado: "Rondônia e Acre", sigla: "RO/AC" },
  { nome: "TRT 15", estado: "São Paulo", sigla: "SP" },
  { nome: "TRT 16", estado: "Maranhão", sigla: "MA" },
  { nome: "TRT 17", estado: "Espírito Santo", sigla: "ES" },
  { nome: "TRT 18", estado: "Goiás", sigla: "GO" },
  { nome: "TRT 19", estado: "Alagoas", sigla: "AL" },
  { nome: "TRT 20", estado: "Sergipe", sigla: "SE" },
  { nome: "TRT 21", estado: "Rio Grande do Norte", sigla: "RN" },
  { nome: "TRT 22", estado: "Piauí", sigla: "PI" },
  { nome: "TRT 23", estado: "Mato Grosso", sigla: "MT" },
  { nome: "TRT 24", estado: "Mato Grosso do Sul", sigla: "MS" },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { estado, arquivamento, acordao, sentenca } = payload[0].payload;
    const trtInfo = trts.find((t) => t.estado === estado || t.nome === estado);
    const estadoNome = trtInfo ? trtInfo.estado : estado;

    return (
      <div style={{ backgroundColor: "#fff", border: "1px solid #ccc", padding: "10px" }}>
        <p>{estadoNome}</p>
        <p style={{ color: "#8884d8" }}>Arquivamento: {arquivamento}</p>
        <p style={{ color: "#ffc658" }}>Acórdão: {acordao}</p>
        <p style={{ color: "#fb8085" }}>Sentença: {sentenca}</p>
      </div>
    );
  }

  return null;
};

const EstadoBarChart = ({ handleStateClick, duracao }) => {
  // Processamento dinâmico dos dados
  const processedData = useMemo(() => {
    if (!duracao) return [];

    const mergedData = {};

    // Iterar sobre os tipos de duração
    Object.entries(duracao).forEach(([key, value]) => {
      value.forEach((item) => {
        Object.entries(item).forEach(([trt, duration]) => {
          const estado = trt.replace("trt_", "TRT ");
          const trtObj = trts.find((t) => t.nome === estado);

          if (!mergedData[estado]) {
            mergedData[estado] = {
              estado,
              sigla: trtObj?.sigla || estado, // Adicionar sigla
              arquivamento: 0,
              acordao: 0,
              sentenca: 0,
              nome: trtObj?.estado || estado
            };
          }

          if (key === "temp_acordao") {
            mergedData[estado].acordao += duration;
          } else if (key === "temp_definitivo") {
            mergedData[estado].arquivamento += duration;
          } else if (key === "temp_sentenca") {
            mergedData[estado].sentenca += duration;
          }
        });
      });
    });

    return Object.values(mergedData);
  }, [duracao]);

  const onBarClick = (data) => {
    handleStateClick(data);
  };

  return (
    <C.DurationContainer>
      <ResponsiveContainer width={2500} height="100%">
        <BarChart data={processedData} margin={{ top: 12 }}>
          <CartesianGrid vertical={false} strokeDasharray="1 0" />
          <XAxis dataKey="sigla" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="arquivamento"
            fill="#8884d8"
            onClick={(data) => onBarClick(data.nome)}
          >
            <LabelList dataKey="arquivamento" position="top" style={{ fontSize: "12px", fill: "#000" }} />
          </Bar>
          <Bar
            dataKey="acordao"
            fill="#ffc658"
            onClick={(data) => onBarClick(data.nome)}
          >
            <LabelList dataKey="acordao" position="top" style={{ fontSize: "12px", fill: "#000" }} />
          </Bar>
          <Bar
            dataKey="sentenca"
            fill="#fb8085"
            onClick={(data) => onBarClick(data.nome)}
          >
            <LabelList dataKey="sentenca" position="top" style={{ fontSize: "12px", fill: "#000" }} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </C.DurationContainer>
  );
};

export default EstadoBarChart;
