import styled from "styled-components";

export const BarEstado = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
  gap: 6px;

  @media (max-width: 715px) {
    display: grid;
  grid-template-areas: 
    "segunda segunda"
    "primeira ultima";
  grid-template-columns: 50% 50%; /* Primeira e última ocupam 50% cada */
  gap: 6px;
  padding: 5px;

  > div:nth-child(1) {
    grid-area: primeira; /* Primeira div na parte inferior esquerda */
  }

  > div:nth-child(2) {
    grid-area: segunda; /* Segunda div ocupa toda a largura na parte superior */
  }

  > div:nth-child(3) {
    grid-area: ultima; /* Última div na parte inferior direita */
  }


  > div > div:not(:last-child) {
    border-bottom: 2px solid #EDF2F6; 
    padding-bottom: 10px;
  }
  }
 
  > div > div:not(:last-child){
    border-bottom: 2px solid #EDF2F6; 
    padding-bottom: 10px;
 
  }

`;

export const Class = styled.div`
  width: 100%;
  min-height: 366px;
`

export const BarContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center; 
  gap: 12px; 
  @media (max-width: 1400px) {
    font-size: 14px;
  }


`;

export const BarTitle = styled.div`
  width: 125px; 
  text-align: left; 
  font-weight: bold;

  @media (max-width: 715px) {
      width: 75px;
    }
`;

export const BarLabel = styled.div`
  text-align: left;
  font-weight: 200;
  margin-bottom: 5px;
  color: var( --color-text-blue);
  line-height: 20px;
  font-size: 14px;

  @media (max-width: 405px) {
      font-size: 12px;
    }
`;

export const ProgressBar = styled.div`
  flex: 1; 
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 70px;

  @media (max-width: 405px) {
      min-width: 70px;
    }
`;

export const Bar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  position: relative;
  transition: width 0.3s;
`;


export const BarValue = styled.div`
  width: 60px; 
  text-align: right; 
  color: var( --color-text-blue);
  line-height: 20px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-size: 12px;

  @media (max-width: 405px) {
      font-size: 10px;
      width: 50px;
    }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  min-height: 310px;
  min-width: 32px;

  @media (max-width: 715px) {
      min-height: 0;
    }
`;

  export const PaginationArrow = styled.button`
    /* position: absolute;
    top: 50%; 
    right: 10px;  */
    transform: translateY(-50%); 
    background: #fff; 
    border: none;
    border-radius: 50%;
    border: 2px solid #bbb;
    padding: 6px; 
    box-shadow: 0 2px 5px #bbb; 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  
  
    svg {
      color: #bbb; 
      font-size: 16px; 
    }
  `;


export const Progress = styled.div.attrs(({ $valor, $cor }) => ({
  style: {
    width: $valor > 0 ? `${Math.max($valor, 1.5)}%` : '0%', 
    backgroundColor: $valor > 0 ? $cor : 'transparent',
    maxWidth: '100%' 
  },
}))`
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s;
`;

