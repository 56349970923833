import React, { useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import * as C from './GraficoBarStyle'; // Importe seus componentes estilizados aqui

const GraficsList = ({ estados, estadosPorPagina, onClickState, ajustarValores, onClickTRT, money }) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const paginasTotais = Math.ceil(estados.length / estadosPorPagina);

    // Calcula o total de processos diretamente dentro do componente
    const totalProcessosFull = estados.reduce((sum, estado) => sum + estado.valor, 0);

    const indiceUltimoEstado = currentPage * estadosPorPagina;
    const indicePrimeiroEstado = indiceUltimoEstado - estadosPorPagina;
    const estadosAtuais = estados.slice(indicePrimeiroEstado, indiceUltimoEstado);

    const getBarColor = (nome) => {
        const verdeStatus = ['COMPRA IMEDIATA', 'COMPRA', 'COMPRA 36M / EMPRÉSTIMO 18X', 'COMPRA 24M / EMPRÉSTIMO 12X', 'EMPRÉSTIMO 24X'];
        const vermelhoStatus = ['PENDÊNCIA PROCESSUAL', 'TESE RUIM', 'VALOR BAIXO', 'Erro de Busca'];
        const amareloStatus = ['ANÁLISE MANUAL', 'EMPRESA INSOLVENTE'];
        const azulStatus = ['Precatório'];

        if (verdeStatus.includes(nome)) return '#4caf50';
        if (vermelhoStatus.includes(nome)) return '#f44336';
        if (amareloStatus.includes(nome)) return '#fbc764';
        if (azulStatus.includes(nome)) return '#8884d8';
        return '#655cf5'; // Padrão
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage < paginasTotais ? prevPage + 1 : 1));
    };

    const handlePreviusPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? paginasTotais : prevPage - 1));
    };

    // Redefine a página para 1 sempre que "estados" mudar
    useEffect(() => {
        setCurrentPage(1);
    }, [estados]);

    const formatWithDecimals = (num) => {
        let formatted = num.toFixed(0).replace('.', ',');  
        if (formatted.endsWith(',00')) {
            formatted = formatted.slice(0, -3);  
        } else if (formatted.endsWith('0')) {
            formatted = formatted.slice(0, -1); 
        }
        return formatted;
    };
    
    const formatValue = (numericValue, isCurrency = false) => {
        let formattedValue;
    
        // Verifica se o valor é monetário
        if (numericValue >= 1e18) {
            formattedValue = formatWithDecimals(numericValue / 1e18) + ' Qi'; // Quintilhão
        } else if (numericValue >= 1e15) {
            formattedValue = formatWithDecimals(numericValue / 1e15) + ' Qa'; // Quadrilhão
        } else if (numericValue >= 1e12) {
            formattedValue = formatWithDecimals(numericValue / 1e12) + ' Tri'; // Trilhão
        } else if (numericValue >= 1e9) {
            formattedValue = formatWithDecimals(numericValue / 1e9) + ' Bi'; // Bilhão
        } else if (numericValue >= 1e6) {
            formattedValue = formatWithDecimals(numericValue / 1e6) + ' M'; // Milhão
        } else {
            formattedValue = numericValue.toLocaleString('pt-BR');
        }
    
        // Se for um valor monetário, adiciona o prefixo "R$"
        return isCurrency ? `R$ ${formattedValue}` : formattedValue;
    };

    return (
        <C.BarEstado>
                <C.PaginationContainer>
            {paginasTotais > 1 && (
                    <C.PaginationArrow onClick={handlePreviusPage}>
                        <IoIosArrowBack />
                    </C.PaginationArrow>
                    )}
                </C.PaginationContainer>
            <C.Class>
                {estadosAtuais.map((estado) => {
                    const porcentagem = (estado.valor / totalProcessosFull) * 100;
                    const cor = getBarColor(estado.nome);

                    return (
                        <C.BarContainer
                            key={estado.nome}
                            onClick={() => {
                                if (ajustarValores) {
                                    ajustarValores(estado.valor); // Chama a função ajustarValores com o valor do estado
                                } else if (onClickState) {
                                    onClickState(estado.nome); // Chama a função onClickState com o nome do estado
                                } else if (onClickTRT) {
                                    onClickTRT(estado.estado);
                                }
                            }}
                        >
                            <C.BarTitle>
                                <C.BarLabel>{estado.nome}</C.BarLabel>
                            </C.BarTitle>
                            <C.ProgressBar>
                                <C.Bar>
                                    <C.Progress $valor={porcentagem} $cor={cor} />
                                </C.Bar>
                            </C.ProgressBar>
                            <C.BarValue>{formatValue(Number(estado.valor), money)}</C.BarValue>
                        </C.BarContainer>
                    );
                })}
            </C.Class>

                <C.PaginationContainer>
                    {paginasTotais > 1 && (
                        <C.PaginationArrow onClick={handleNextPage}>
                            <IoIosArrowForward />
                        </C.PaginationArrow>
                    )}
                </C.PaginationContainer>
        </C.BarEstado>
    );
};

export default GraficsList;
