import styled from "styled-components";

export const ChartContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 24px 12px 24px 12px;
    border-radius: 10px;
    background: var(--color-white-variante);
`;

export const Button = styled.button`
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #655cf5;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
`;

export const Option = styled.option`
    background-color: #fff;
    color: #333;
    text-align: left;
    
    @media (max-width: 405px) {
        font-size: 9px;
    }
`;

export const Select = styled.select`
    text-align: center;
    padding: 8px 20px;
    font-size: .75rem;
    border-radius: 10px;
    border: 1px solid var( --color-text-blue);;
    background-color: #fff;
    color: var( --color-text-blue);
    cursor: pointer;
    appearance: none; /* Oculta a seta padrão */
    -webkit-appearance: none; /* Para Safari */
    -moz-appearance: none; /* Para Firefox */

    &:focus {
        outline: none;
        border-color: #ccc;
    }

`;

export const ControlContainer =  styled.div`
    align-self: flex-end;
    margin-bottom: 20px;
`;