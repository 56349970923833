import React, { useEffect, useState } from "react";
import "../../../styles/variables.css";
import * as C from "./DownloadXlsxStyles";
import Button from "../../../components/button/Button";
import Input from "../../../components/inputs/Input";
import { FecthListDownloads, FecthDownloadsXlsx, DownloadProcessados } from "../../../service/ServiceApi";
import { BsSearch } from "react-icons/bs";

const DownloadXlsx = ({    search, 
    setSearch, handleCPFSearch, handleSearch, handleSearchClick, onNavLinkClick }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState("Selecione ou arraste um arquivo xlsx");
    const [isValid, setIsValid] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const ITEMS_PER_PAGE = 10;

    const fetchData = async () => {
        try {
            const data = await FecthListDownloads();

            const formattedData = Object.entries(data).map(([fileName, item], index) => ({
                id: index + 1,
                processo: fileName || "Desconhecido",
                nome: item.user || "Usuário Desconhecido",
                dataInclusao: new Date(item.timestamp),
                dataFinalizacao: item.time_look || "",
                progresso: item.status || "Indefinido",
                arquivo: item.file_name_token || "",
                status: item.statusSn || "",
            }));

            // Ordena por data de inclusão mais recente
            const sortedData = formattedData.sort((a, b) => b.dataInclusao - a.dataInclusao);

            setTableData(sortedData);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, 5 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                setFile(selectedFile);
                setError(selectedFile.name);
                setIsValid(true);
            } else {
                setError("Por favor, selecione um arquivo xlsx.");
                setFile(selectedFile);
                setIsValid(false);
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            if (droppedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                setFile(droppedFile);
                setError(droppedFile.name);
                setIsValid(true);
            } else {
                setError("Por favor, selecione um arquivo xlsx.");
                setFile(droppedFile);
                setIsValid(false);
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError("Nenhum arquivo selecionado.");
            return;
        }
        if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setError("O arquivo selecionado não é um xlsx.");
            return;
        }

        const maxFileSize = 300 * 1024 * 1024; // 300 MB
        if (file.size > maxFileSize) {
            setError("O arquivo selecionado excede o tamanho máximo permitido de 300 MB.");
            return;
        }

        const payload = {
            user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id,
            name: file.name,
            xlsx: file,
        };

        setIsUploading(true);

        try {
            await FecthDownloadsXlsx(payload);
            fetchData();

            setTimeout(() => {
                setFile(null);
                setError("Selecione ou arraste um arquivo xlsx");
                setIsValid(false);
            }, 1000);
        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            setError("Erro ao fazer upload. Tente novamente.");
            setIsValid(false);
        } finally {
            setIsUploading(false);
        }
    };

    const formatProgress = (progress) => {
        switch (progress) {
            case "Na Fila de Processamento":
            case "Aguardando o Processamento":
                return "Aguardando";
            case "Processamento Concluido":
            case "Concluido":
                return "Concluído";
            case "Erro no PDF":
                return "Erro";
            default:
                return progress || "Indefinido";
        }
    };

    const startIdx = currentPage * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    const currentItems = tableData.slice(startIdx, endIdx);
    const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const formatInput = (value) => {
        const onlyNumbers = value.replace(/\D/g, '');
        let formattedValue = value;
    
        if (value.startsWith('(')) {
            if (onlyNumbers.length === 11) {
                // Telefone celular
                formattedValue = onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            } else if (onlyNumbers.length === 10) {
                // Telefone fixo
                formattedValue = onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            }
        } else if (onlyNumbers.length === 11) {
            // CPF
            formattedValue = onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (onlyNumbers.length === 14) {
            // CNPJ
            formattedValue = onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else if (onlyNumbers.length === 20) {
            // Processo
            formattedValue = onlyNumbers.replace(/(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/, '$1-$2.$3.$4.$5.$6');
        }
    
        return formattedValue;
    };
    
    // Regex Patterns
    const patterns = {
        trabalhista: /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/,
        precatorio: /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/,
        cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{11}$/,
        cnpj: /^\d{14}$|(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2})/,
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        telefone: /^\(\d{2}\)\s?\d{4,5}-\d{4}$/
    };
    
    // Função para determinar o tipo de busca
    const determineSearchType = (value) => {
        if (patterns.trabalhista.test(value)) return 'trabalhista';
        if (patterns.precatorio.test(value)) return 'precatorio';
        if (patterns.cpf.test(value)) return 'cpf';
        if (patterns.cnpj.test(value)) return 'cnpj';
        if (patterns.email.test(value)) return 'email';
        if (patterns.telefone.test(value)) return 'telephone';
        return 'nome'; // Presume nome se não encaixar nas categorias acima
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const trimmedSearch = search.trim();
        if (!trimmedSearch) return;

        onNavLinkClick("Consultas")
        const type = determineSearchType(trimmedSearch);
        const cleanSearch = type === 'telephone' ? formatInput(trimmedSearch).replace(/[^\d]/g, '') : trimmedSearch;

        if (type === 'cpf' || type === 'cnpj' || type === 'email' || type === 'telephone'|| type === 'nome') {
            handleCPFSearch(cleanSearch, type);
        } else {
            handleSearch(cleanSearch, type);
        }
    };

    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = async (iten) => {
        if (isDownloading) return; // Evita múltiplas chamadas enquanto está em execução.

        setIsDownloading(true); // Desabilita enquanto processa.
        try {
            await DownloadProcessados(iten);
        } catch (error) {
            console.error("Erro durante o download:", error);
        } finally {
            setIsDownloading(false); // Habilita novamente após o término.
        }
    };


    return (
        <C.ArticleConteiner>
            <C.SearchContainer onSubmit={onSubmit}>
                <C.InputBody onClick={handleSearchClick}>
                    <BsSearch />
                    <Input
                        type="text"
                        placeholder="Busca por CPF, nome, telefone com ( ), email, processo, CNPJ ou empresa"
                        id="input_search"
                        autocomplete="off"
                        value={search}
                        onChange={(e) => setSearch(formatInput(e.target.value))}
                    />
                </C.InputBody>
                <Button Text="Consultar" type="submit" />
            </C.SearchContainer>
            <C.UploadContainer>
                <C.FormBox onSubmit={handleSubmit} onDrop={handleDrop} onDragOver={handleDragOver}>
                    <C.InputFileBox>
                        <Input
                            type="file"
                            id="pdf-upload"
                            onChange={handleFileChange}
                            autoComplete="off"
                            className="file-input"
                        />
                        <C.CircleLoppingBox>
                            <C.CircleLopping htmlFor="pdf-upload" $isValid={isValid}>
                                <C.LabelInitBox htmlFor="pdf-upload" $isValid={isValid}>
                                    {error}
                                </C.LabelInitBox>
                            </C.CircleLopping>
                        </C.CircleLoppingBox>
                    </C.InputFileBox>
                    {isUploading ? (
                        <C.animateSpan>Carregando...</C.animateSpan>
                    ) : (
                        <Button Text="Enviar" type="submit" />
                    )}
                </C.FormBox>
            </C.UploadContainer>
            {tableData.length > 0 && (
                <C.UploadContainer>
                    <C.FormTable>
                        <C.Header>
                            <C.HeaderTitle>Ordens</C.HeaderTitle>
                        </C.Header>
                        <C.Envelope>
                        <C.Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Processo</th>
                                    <th style={{ textAlign: "right" }}>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr key={item.id}>
                                        {/* Número sequencial */}
                                        <td>{index + 1}</td>

                                        {/* Processo e Progresso */}
                                        <td>
                                            <div>
                                                {item.processo ? (
                                                    <div>{item.arquivo}</div>
                                                ) : (
                                                    <div>Sem processo</div>
                                                )}
                                                {item.progresso && (
                                                    <C.ProgressIndicator $progress={item.progresso}>
                                                        {formatProgress(item.progresso)}
                                                    </C.ProgressIndicator>
                                                )}
                                            </div>
                                        </td>

                                        {/* Download, alinhado à direita */}
                                        <C.tdDownload>
                                            {["Concluido", "Concluído", "Conclu\u00eddo", "Processamento Concluido"].includes(item.progresso) && (
                                                <C.TagDownload onClick={() => handleDownload(item.processo)}>Download</C.TagDownload>
                                            )}
                                        </C.tdDownload>
                                    </tr>
                                ))}
                            </tbody>
                        </C.Table>
                    </C.Envelope>
                        {totalPages > 1 && (
                            <C.PaginationContainer>
                                {Array.from({ length: totalPages }).map((_, idx) => (
                                    <C.PageDot
                                        key={idx}
                                        onClick={() => handlePageChange(idx)}
                                        $isActive={idx === currentPage}
                                    />
                                ))}
                            </C.PaginationContainer>
                        )}
                    </C.FormTable>
                </C.UploadContainer>
            )}
        </C.ArticleConteiner>
    );
};

export default DownloadXlsx;
