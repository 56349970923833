import React from 'react';
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";
import * as C from '../UsuariosStyles';


// usado para vincular o label com o numero de autorização vindo do backend valor 0 considera apenas o label da permissao 
const PERMISSIONS = {
    1: { 
        label: "Consultas", 
        subpermissoes: [
            { nome: "Status", valor: 1 }, 
            { nome: "Polos", valor: 2 }, 
            { nome: "Movimentações", valor: 4 },
            { nome: "Palavras-chave", valor: 3 },
            { nome: "Links", valor: 9}, 
        ]
    },
    2: { 
        label: "Usuários", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
    3: { 
        label: "Advogados", 
        subpermissoes: [
            { nome: "Visualização", valor: 5 }, 
            { nome: "Edição", valor: 6 }
        ]
    },
    4: { 
        label: "Empresas", 
        subpermissoes: [
            { nome: "Visualização", valor: 7 }, 
            { nome: "Edição", valor: 8 }
        ]
    },
    5: { 
        label: "Dashboard", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
    6: { 
        label: "Consulta em Lote", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
    7: { 
        label: "Análise AIA", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
};


// Componente do corpo da tabela expansiva para obter mais informações do usuário
const TableRow = ({ user, index, isOpen, toggleUserInfo, handleEditClick, handleRemove }) => (
    <>
        <C.UsuariosTr onClick={() => toggleUserInfo(index)}>
            <C.UsuariosTd>{user.Ap_Nome}</C.UsuariosTd>
            <C.UsuariosTd>{user.Ap_User}</C.UsuariosTd>
            <C.UsuariosTd>{user.Ap_Email}</C.UsuariosTd>
            <C.UsuariosToggleIcon>
                {isOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </C.UsuariosToggleIcon>
        </C.UsuariosTr>
        {isOpen && (
            <C.UsuariosUserInfoTr>
                <C.UsuariosInfoTd>
                    <UserDetails
                        user={user}
                        handleEditClick={() => handleEditClick(user)}
                        handleRemove={() => handleRemove(user)}
                    />
                </C.UsuariosInfoTd>
            </C.UsuariosUserInfoTr>
        )}
    </>
);

const UserDetails = ({ user, handleEditClick, handleRemove }) => {
    const renderPermissions = (permissions) => {
        const hasPermissions = Object.keys(permissions).length > 0;

        if (!hasPermissions) {
            return <div>Nenhuma permissão configurada.</div>;
        }

        return Object.keys(PERMISSIONS).map(permId => {
            // Verifica se a permissão existe e se tem subpermissões
            if (!permissions[permId]) {
                return null; // Não renderiza se a permissão não existe
            }

            const permName = PERMISSIONS[permId].label;

            // Se não houver subpermissão ou ela estiver vazia, trata como [0]
            const subPermissoes = permissions[permId].subpermissao?.length > 0 
                ? permissions[permId].subpermissao 
                : [0];

            return (
                <C.UsuariosUserAuth key={permId}>
                    <strong>{permName}:</strong> {PERMISSIONS[permId].subpermissoes
                        .filter(subPermissao => subPermissoes.includes(subPermissao.valor))
                        .map(subPermissao => (
                            <span key={subPermissao.valor}>
                                {subPermissao.nome}
                            </span>
                        ))
                    }
                </C.UsuariosUserAuth>
            );
        });
    };

    return (
        <C.UsuariosUserInfo>
            <C.UsuariosUserDetail><strong>Nome:</strong> {user.Ap_Nome} {user.Ap_Sobrenome}</C.UsuariosUserDetail>
            <C.UsuariosUserDetail><strong>Username:</strong> {user.Ap_User}</C.UsuariosUserDetail>
            <C.UsuariosUserDetail><strong>Email:</strong> {user.Ap_Email}</C.UsuariosUserDetail>
            <C.UsuariosUserDetail>
                <strong>Permissões:</strong> 
            </C.UsuariosUserDetail>
            <C.UsuariosUserDetailAuth>
                {renderPermissions(user.Permissoes || {})}
            </C.UsuariosUserDetailAuth>
            <C.UsuariosEditDeleteContainer>
                <C.UsuariosEditButton onClick={handleEditClick}>Editar</C.UsuariosEditButton>
                <C.UsuariosDeleteButton onClick={handleRemove}>Deletar</C.UsuariosDeleteButton>
            </C.UsuariosEditDeleteContainer>
        </C.UsuariosUserInfo>
    );
};

export { TableRow, UserDetails };