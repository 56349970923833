import styled from "styled-components";

export const BarEstado = styled.div`
  /* min-height: 600px;  */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Mantém o conteúdo normal no topo */
`;

export const BarContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  min-width: 300px;

  @media (max-width: 1400px) {
    width: 100%;
    font-size: 14px;
    min-width: 200px;
  }
`;

export const BarTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
`;

export const BarLabel = styled.div`
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
  color: var( --color-text-blue);
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Garante que o número fique alinhado à direita */
`;

export const Bar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  position: relative;
  transition: width 0.3s;
`;


// Componente BarValue
export const BarValue = styled.span`
  margin-left: 10px;
  font-weight: bold;
  color: var( --color-text-blue);
  min-width: 50px; /* Largura mínima para alinhar os números */
  text-align: right; /* Alinha os números à direita */
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

export const PaginationDot = styled.div.attrs(({ $isActive }) => ({
  style: {
    backgroundColor: $isActive ? '#655cf5' : '#ddd',
  },
}))`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ $isActive }) => ($isActive ? '#655cf5' : '#bbb')};
  }
`;


export const Progress = styled.div.attrs(({ $valor, $cor }) => ({
  style: {
    width: $valor > 0 ? `${Math.max($valor, 1)}%` : '0%', 
    backgroundColor: $valor > 0 ? $cor : 'transparent', 
  },
}))`
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s;
  visibility: ${({ $valor }) => ($valor > 0 ? 'visible' : 'hidden')}; 
`;