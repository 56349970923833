import styled from "styled-components";

export const TableWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 24px 12px;
  border-radius: 10px;
  background: var(--color-white-variante);
`;

export const Envelopes = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const ScrollableTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  position: relative;
  border: 1px solid #ddd;
`;

export const TableCell = styled.td`
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
`;

export const TableHeader = styled.th`
  background-color: #f4f4f4;
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  align-self: flex-end;
  align-items: center;
`;

export const Option = styled.option`
  background-color: #fff;
  color: #333;
  text-align: left;

  @media (max-width: 405px) {
    font-size: 9px;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #655cf5;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
`;

export const Select = styled.select`
  text-align: center;
  padding: 8px 20px;
  font-size: 0.75rem;
  border-radius: 10px;
  border: 1px solid var(--color-text-blue);
  background-color: #fff;
  color: var(--color-text-blue);
  cursor: pointer;
  appearance: none; /* Oculta a seta padrão */
  -webkit-appearance: none; /* Para Safari */
  -moz-appearance: none; /* Para Firefox */

  &:focus {
    outline: none;
    border-color: #ccc;
  }
`;