import React, { useState, useEffect } from "react";
import Input from "../../../../components/inputs/Input";
import Button from "../../../../components/button/Button";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { saveUser, Logis } from "../../../../service/ServiceApi";
import * as  C from "./UserSettingsStyles";
import "../../../../styles/variables.css";


//componente de controle para renderizar na tela cadatros e de edição [12/08/2024]

const PERMISSIONS = {
    1: { 
        label: "Consultas", 
        subpermissoes: [
            { nome: "Status", valor: 1 }, 
            { nome: "Polos", valor: 2 }, 
            { nome: "Movimentações", valor: 4 },
            { nome: "Palavras-chave", valor: 3 },
            { nome: "Links", valor: 9}, 
        ]
    },
    2: { 
        label: "Usuários", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
    3: { 
        label: "Advogados", 
        subpermissoes: [
            { nome: "Visualização", valor: 5 }, 
            { nome: "Edição", valor: 6 }
        ]
    },
    4: { 
        label: "Empresas", 
        subpermissoes: [
            { nome: "Visualização", valor: 7 }, 
            { nome: "Edição", valor: 8 }
        ]
    },
    5: { 
        label: "Dashboard", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
    6: { 
        label: "Consulta em Lote", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
    7: { 
        label: "Análise AIA", 
        subpermissoes: [
            { nome: "Visualização", valor: 0 }
        ]
    },
};

const UserSettings = ({ userRosterEdit, setUserRosterEdit, fetchData }) => {
    const [formData, setFormData] = useState({
        nome: "",
        sobrenome: "",
        email: "",
        password: "",
        user: "",
        permissoes: Object.keys(PERMISSIONS).reduce((acc, key) => {
            const subpermissoesLength = PERMISSIONS[key].subpermissoes?.length || 0;
            acc[key] = Array(subpermissoesLength).fill(false);
            return acc;
        }, {}),
    });

    const [prevs, setPrevs] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState("");

    useEffect(() => {
        if (userRosterEdit && Object.keys(userRosterEdit).length > 0) {
            const permissoes = Object.keys(PERMISSIONS).reduce((acc, key) => {
                const subpermissao = userRosterEdit.Permissoes[key]?.subpermissao || null;
    
                if (subpermissao === null) {
                    // Se a permissão não está presente em userRosterEdit.Permissoes, desmarque todos os checkboxes
                    acc[key] = Array(PERMISSIONS[key].subpermissoes.length).fill(false);
                } else if (subpermissao.length === 0 && PERMISSIONS[key].subpermissoes.length === 1 && PERMISSIONS[key].subpermissoes[0].valor === 0) {
                    // Se a permissão está presente, mas sem subpermissões marcadas, e a única subpermissão tem valor 0, marque
                    acc[key] = [true];
                } else {
                    // Caso contrário, marque/desmarque de acordo com as subpermissões presentes
                    acc[key] = PERMISSIONS[key].subpermissoes.map(sub =>
                        subpermissao.includes(sub.valor)
                    );
                }
    
                return acc;
            }, {});
    
            setFormData({
                nome: userRosterEdit.Ap_Nome || "",
                sobrenome: userRosterEdit.Ap_Sobrenome || "",
                email: userRosterEdit.Ap_Email || "",
                password: userRosterEdit.password || "",
                user: userRosterEdit.Ap_User || "",
                id: userRosterEdit.Ap_Id || "", 
                permissoes,
            });
        } else {
            resetFormData();
        }
    }, [userRosterEdit]);
    
    
    
    
    
    const resetFormData = () => {
        setFormData({
            nome: "",
            sobrenome: "",
            email: "",
            password: "",
            user: "",
            id: "", 
            permissoes: Object.keys(PERMISSIONS).reduce((acc, key) => {
                acc[key] = Array(PERMISSIONS[key].subpermissoes.length).fill(false);
                return acc;
            }, {}),
        });
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({ ...prevData, [id]: value }));
    };

    const handleSubpermissaoChange = (permId, index) => {
        setFormData(prevData => {
            const updatedPermissoes = { ...prevData.permissoes };
            if (!updatedPermissoes[permId]) {
                updatedPermissoes[permId] = Array(PERMISSIONS[permId].subpermissoes.length).fill(false);
            }
            updatedPermissoes[permId][index] = !updatedPermissoes[permId][index];
    
            return {
                ...prevData,
                permissoes: updatedPermissoes,
            };
        });
    };

    
    const handleSave = async () => {
        const updatedPermissoes = { ...formData.permissoes };
    
        // Se todas as subpermissões de "Consultas" estiverem desmarcadas, marca todas
        if (updatedPermissoes["1"] && updatedPermissoes["1"].every(checked => checked === false)) {
            const subPermissoesLength = PERMISSIONS["1"].subpermissoes.length;
            updatedPermissoes["1"] = Array(subPermissoesLength).fill(true);
            updatedPermissoes["1"][subPermissoesLength - 1] = false; 
        }
    
        const createPermissoesPayload = () => {
            return Object.keys(updatedPermissoes).reduce((acc, permId) => {
                const subpermissoes = updatedPermissoes[permId]
                    .map((checked, index) => checked ? PERMISSIONS[permId].subpermissoes[index].valor : null)
                    .filter(value => value !== null);
    
                if (PERMISSIONS[permId].subpermissoes.length === 1 && subpermissoes.length > 0) {
                    acc[permId] = { subpermissao: [] };
                } else if (subpermissoes.length > 0) {
                    acc[permId] = { subpermissao: subpermissoes };
                }
    
                return acc;
            }, {});
        };
    
        const payload = {
            nome: formData.nome,
            sobrenome: formData.sobrenome,
            email: formData.email,
            user: formData.user,
            permissoes: createPermissoesPayload(),
        };
    
        let message = '';
        let logDescription = '';
    
        if (!userRosterEdit || Object.keys(userRosterEdit).length === 0) {
            // Criando um novo usuário
            payload.password = formData.password;
            message = "Novo usuário criado com sucesso!";
            logDescription = `Novo usuário criado: ${payload.nome} ${payload.sobrenome}, e-mail: ${payload.email}`;
        }
    
        if (userRosterEdit && Object.keys(userRosterEdit).length > 0) {
            // Editando um usuário existente
            payload.id = formData.id;
            message = "Usuário editado com sucesso!";
            logDescription = `Usuário editado: ${payload.nome} ${payload.sobrenome}. e-mail: ${payload.email}`;
        }
    
        try {
            await saveUser(payload, !!Object.keys(userRosterEdit).length);
            resetFormData();
            fetchData();
            setNotificationMessage({ type: "success", text: message });
    
            // Registrar o log da ação
            Logis({
                route: '/api/v1/users',
                descricao: logDescription,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
            });
    
            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
            
        } catch (error) {
            console.error("Erro ao salvar usuário:", error);
            if (error.response && error.response.data && error.response.data.error === 'User already exists') {
                setNotificationMessage({ type: 'error', text: "O e-mail ou nome de usuário já está em uso. Por favor, escolha outro." });
            } else {
                setNotificationMessage({ type: 'error', text: "Erro ao salvar usuário, tente novamente mais tarde" });
            }
    
            // Registrar o log de erro
            Logis({
                route: `/api/v1/users`,
                descricao: `Erro ao salvar usuário ${payload.nome} ${payload.sobrenome}. Erro: ${error.message}`,
                user_id: JSON.parse(localStorage.getItem("user_token"))?.user?.id
            });
    
            setTimeout(() => {
                setNotificationMessage("");
            }, 5000);
        }
    };
    
    
    const handleCancel = () => {
        setUserRosterEdit({});
        resetFormData();
    };

    const prevsCkick = () => {
        setPrevs(prevState => !prevState);
    };

    return (
        <C.UserSettingsContainer>
            <div>
                <C.UserSettingsButtonGroup>
                    <Button Text="Salvar" type="button" onClick={handleSave} />
                    <Button Text="Cancelar" type="button" onClick={handleCancel} />
                </C.UserSettingsButtonGroup>
                <C.UserSettingsPrevsButton>
                    <C.UserSettingsTitle>Cadastro</C.UserSettingsTitle>
                    <Button
                        Text={prevs ? <GoArrowRight /> : <GoArrowLeft />}
                        type="button"
                        onClick={prevsCkick}
                    />
                </C.UserSettingsPrevsButton>
                <div>
                    <C.UserSettingsForm>
                        <C.UserSettingsFieldSet $show={prevs}>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="nome">Nome</C.UserSettingsLabel>
                                <Input
                                    type="text"
                                    value={formData.nome}
                                    id="nome"
                                    autocomplete={"off"}
                                    onChange={handleChange}
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="sobrenome">Sobrenome</C.UserSettingsLabel>
                                <Input
                                    type="text"
                                    autocomplete={"off"}
                                    value={formData.sobrenome}
                                    id="sobrenome"
                                    onChange={handleChange}
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="email">Email</C.UserSettingsLabel>
                                <Input
                                    type="email"
                                    autocomplete={"off"}
                                    value={formData.email}
                                    id="email"
                                    onChange={handleChange}
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="user">Username</C.UserSettingsLabel>
                                <Input
                                    type="text"
                                    autocomplete={"off"}
                                    value={formData.user}
                                    id="user"
                                    onChange={handleChange}
                                />
                            </C.UserSettingsField>
                            <C.UserSettingsField>
                                <C.UserSettingsLabel htmlFor="password">Senha</C.UserSettingsLabel>
                                <Input
                                    type="password"
                                    autocomplete={"off"}
                                    value={formData.password}
                                    id="password"
                                    onChange={handleChange}
                                />
                            </C.UserSettingsField>
                        </C.UserSettingsFieldSet>
                    </C.UserSettingsForm>
                </div>
            </div>
            <C.UserSettingsFieldSet $show={!prevs}>
                <C.UserSettingsPermissoes>
                {Object.keys(PERMISSIONS).map((permId) => (
                            <div key={permId}>
                                <h3>{PERMISSIONS[permId].label}</h3>
                                {PERMISSIONS[permId].subpermissoes.map((subperm, index) => (
                                    <C.UserSettingsCheckboxGroup key={index}>
                                        <C.UserSettingsCheckbox
                                            type="checkbox"
                                            id={`perm-${permId}-${index}`}
                                            checked={formData.permissoes[permId] && formData.permissoes[permId][index] ? formData.permissoes[permId][index] : false}
                                            onChange={() => handleSubpermissaoChange(permId, index)}
                                        />
                                        <label htmlFor={`perm-${permId}-${index}`}>
                                            {subperm.nome}
                                        </label>
                                    </C.UserSettingsCheckboxGroup>
                                ))}
                            </div>
                        ))}
                </C.UserSettingsPermissoes>
            </C.UserSettingsFieldSet>
            {notificationMessage && (
                <C.NotificationMessage $type={notificationMessage.type}>
                    {notificationMessage.text}
                </C.NotificationMessage>
            )}
        </C.UserSettingsContainer>
    );
};

export default UserSettings;