import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LabelList } from "recharts";

const getYAxisFontSize = () => {
  return window.innerWidth < 570 ? '12px' : '14px'; // Ajuste os tamanhos conforme necessário
};

const formatNumberBR = (number) => {
  return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 }).format(number);
};

const FaseBarChart = ({ instancias, labels }) => {
  const data = [
    { status: labels?.primeira || "1ª Instância", ativos: instancias[0]?.primeira },
    { status: labels?.segunda || "2ª Instância", ativos: instancias[0]?.segunda },
    { status: labels?.terceira || "Instância Superior", ativos: instancias[0]?.terceira },
  ];
  // Função para lidar com o clique na área do gráfico

  return (
    <ResponsiveContainer width="100%" height={340}>
      <BarChart data={data} layout="vertical" margin={{ left: -20, right: 55 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis 
          dataKey="status" 
          type="category" 
          angle={-90} 
          textAnchor="middle"
          tick={{ width: 200, wordBreak: "break-word" }}
          style={{ fontSize: getYAxisFontSize(), fill: '#000', fontFamily: 'system-ui' }}
        />
        <XAxis type="number" tickFormatter={(value) => formatNumberBR(value)} />
        <Tooltip formatter={(value) => formatNumberBR(value)} />
        <Bar dataKey="ativos" fill="#8884d8">
          <LabelList 
            dataKey="ativos" 
            position="right" 
            formatter={formatNumberBR} 
            style={{ fontSize: getYAxisFontSize(), fill: '#000' }} 
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};


export default FaseBarChart;
