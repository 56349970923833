
import styled, { keyframes } from 'styled-components';

const statusColors = {
  "COMPRA IMEDIATA": {
      background: "green",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "PENDÊNCIA PROCESSUAL": {
      background: "orange",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "TESE RUIM": {
      background: "red",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "COMPRA": {
      background: "green",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "COMPRA 24M / EMPRÉSTIMO 12X": {
      background: "var(--color-green)",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "EMPRESA INSOLVENTE": {
      background: "gray",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "COMPRA 36M / EMPRÉSTIMO 18X": {
      background: "#13570a",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "Apto a compra": {
    background: "green",
    color: "var(--color-white)",
    borderColor: "var(--color-white)",
  },
  "APTO A COMPRA":{
    background: "green",
    color: "var(--color-white)",
    borderColor: "var(--color-white)",
  },
  "EMPRÉSTIMO 24X": {
      background: "#276000",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  },
  "ANÁLISE MANUAL": {
      background: "yellow",
      color: "var(--color-black)",
      borderColor: "var(--color-black)",
  },
  "ANALISE MANUAL": {
    background: "yellow",
    color: "var(--color-black)",
    borderColor: "var(--color-black)",
  },
  "VALOR BAIXO": {
      background: "red",
      color: "var(--color-black)",
      borderColor: "var(--color-white)",
  },
  "Valor baixo": {
    background: "red",
    color: "var(--color-black)",
    borderColor: "var(--color-black)",
},
  "Erro de Busca, verifique o número do processo pesquisado!": {
      background: "var(--color-danger)",
      color: "var(--color-white)",
      borderColor: "var(--color-black)",
  },
  "Precatório ou Precatório Trabalhista (válido pelo número)": {
      background: "var(--color-yellow)",
      color: "var(--color-black)",
      borderColor: "var(--color-black)",
  },
  "Empresa Insolvente": {
      background: "var(--color-red)",
      color: "var(--color-white)",
      borderColor: "var(--color-black)",
  },
  "Precatório Novo": {
      background: "var(--color-variant-blue)",
      color: "var(--color-white)",
      borderColor: "var(--color-white)",
  }
};

export const ArticleConteiner = styled.article`
  display: flex;
  flex-direction: column;
  gap:24px;
`;


export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  gap: 10px;
  background: var(--color-grayBlue);

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    margin-bottom: 10px;
  }

  p {
    color: red;
    margin-bottom: 10px;
  }

  button {
    max-width: 180px;
  }

`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px;
  gap: 5px;

  >div:first-child{
    width: 100%;
  }
`;

export const InputFileBox = styled.div`
  input#pdf-upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
    display: none;
  }
`;

export const LabelBox = styled.label`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: ${({ $isValid }) => ($isValid ? 'var(--color-green)' : 'var(--color-red)')};;
    font-size: 20px;
  }
  display: none;
  visibility: hidden;
  transition: visibility 0s, opacity 1s linear;
`;

export const LabelInitBox = styled.label`
  padding-bottom: 10px;
  text-align: center;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isValid }) => ($isValid ? 'var(--color-green)' : 'var(--color-red)')};
  cursor: pointer;
  transition: visibility 0s, opacity 2s linear;
`;

export const CircleLoppingBox = styled.div`
  min-height: 180px;
`;

export const CircleLopping = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  width: 100%;
  min-height: 160px;
  position: relative;
  cursor: pointer;
  transition: 1s;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px solid var(--color-gray);
    border-color: ${({ $isValid }) => ($isValid ? 'var(--color-green)' : 'var(--color-red)')};
    z-index: 1;
    transition: 1s;
  }

  &:hover {
    width: 100%;
  }
`;
const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

export const animateSpan =styled.span`
  font-size: 1rem; 
  font-weight: bold; 
  color: var(--color-primary); 
  animation: ${pulse} 1.5s infinite;
  display: inline-block;
  height: 28px;
`;


export const FormBooble = styled.div`
  background: var(--color-white);
  font-size: 12px;
  text-align: center;
  padding: 5px;
  border: 1px solid var(--color-neutral);
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%; 
    left: 50%; 
    transform: translateX(-50%);
    border-width: 8px; 
    border-style: solid;
    border-color: transparent transparent var(--color-neutral) transparent;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 100%; 
    left: 50%; 
    transform: translateX(-50%);
    border-width: 7px; 
    border-style: solid;
    border-color: transparent transparent var(--color-white) transparent;
    margin-bottom: -1px; 
  }
`;

export const FormTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 5px;
  background: var(--color-white);
  border-radius: 10px;
  width: 100%;
  overflow-x: auto;
  box-sizing: border-box; 

  >div:first-child{
    width: 100%;
    overflow-x: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const HeaderTitle = styled.div`

  font-weight: bold;
  font-size: 20px;
`;

export const FilterButton = styled.button`
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const FilterBox = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const Envelope = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  
  tbody tr{
    width: 100%;
    height: 100px;
  }
  
  th, td {
    padding: 8px 12px;
    text-align: left;
  }
  
  th {
    font-weight: bold;
  }
  td{
    border-bottom: 1px solid var(--color-secondary);
    font-size: 14px;
  }

   td:nth-child(2){
    max-width: 20%;
  } 

  td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(7), td:nth-child(6) {
    max-width: 15%;
    min-width: 10%;
  }
  td:nth-child(7){
    width: 180px;
  }
  
  td:nth-child(6){
    max-width: 450px;
  }

  td:nth-child(1){
    max-width: 50px;
  }

  th:nth-child(4), td:nth-child(4), th:nth-child(3), td:nth-child(3){
    text-align: center;
  }

`;

export const ProgressIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 12px;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    background-color: ${({ $progress }) => {
        switch ($progress) {
            case "Aguardando o Processamento":
            case "Na Fila de Processamento":
            case "Na fila para processamento":
                return "blue";
            case "Concluido":
            case "Conclu\u00eddo":  
            case "Processamento Concluido":
                return "green";
            case "Erro no PDF":
                return "red";
            case "pagina acima de 8K":
                return "orange";
            case "Em Processamento":
                return "yellow";
            case "Processando":
            case "Em processamento":
              return "yellow";
            default:
                return "gray"; 
        }
    }};
  }
`;


export const Status = styled.div`
    color: var(--color-white);
    text-align: center;
    white-space: nowrap;
    padding: 0.3125rem 0.625rem; 
    border-radius: 0.3125rem; 
    font-size: 0.75rem; 
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${({ $status }) => statusColors[$status]?.background || 'red'};
    color: ${({ $status }) => statusColors[$status]?.color || 'var(--color-black)'};
    border-color: ${({ $status }) => statusColors[$status]?.borderColor || 'var(--color-black)'};
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

export const PageDot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$isActive ? "var(--color-primary)" : "#ccc"};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const SearchContainer = styled.form`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    padding-bottom: 12px;

    button{
        max-width: 9.375rem; /* 150px */
    }
`;

export const InputBody = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3125rem; /* 5px */
    width: 100%;
    border-radius: 1.25rem; /* 20px */
    background: var(--color-grayBlue);
    cursor: text;

    svg{
        font-size: 1rem; /* 16px */
        margin-left: 0.625rem; /* 10px */
    }

    input{
        border-radius: 1.25rem; /* 20px */
        background-color: var(--color-grayBlue);
    }
`;

export const TagDownload = styled.div`
    color: red;
    cursor: pointer;

    &:hover{
      color: var(--color-primary);
    }
`;

export const tdDownload = styled.td`
  text-align: right;
  width: 100%;
  
  div{
    text-align: right;
  }
`;
