// url base para as requisições [12/08/2024]

const url = 'https://apilmbeta.lawmetrix.com.br';



const headers = {
    'Content-Type': 'application/json',
};



//requisição de login  [12/08/2024]
 
export async function LoginRequest(username, password){
    try {
            const response = await fetch(url + '/api/v1/login', {
                method: 'POST',
                headers,
                body: JSON.stringify({ username: username, password: password }),
      });
  
      
      const data = await response.json();
      return data;
    } catch (error) {
        return error;
    }
};

// =============================== validação de email =====================================

export async function LoginSendToken(email){
    try {
            const response = await fetch(url + '/api/v1/sendtoken', {
                method: 'POST',
                headers,
                body: JSON.stringify({ email: email }),
      });
  
      
      const data = await response.json();
      return data;
    } catch (error) {
        return error;
    }
};

export async function LoginWithToken(userId, token){
    try {
        const response = await fetch(url + '/api/v1/authenticatetoken', {
            method: 'POST',
            headers,
            body: JSON.stringify({ user_id: userId, token: token }),
        });
        
        
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

// ==================== requisição de processo por numero do processo  [12/08/2024] ===========================

export async function ConsultProcess(processo, type) {
    const baseUrl = url+`/api/v1/processo/${type}/${processo}`
    try {
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}

export async function ConsultTermos(processo) {
    try {
        const response = await fetch(url + `/api/v1/processos/${processo}/termos`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}


export async function ConsultMovimentacao(processo) {
    try {
        const response = await fetch(url + `/api/v1/processos/${processo}/timeline`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}


export async function ConsultCPF(processo, type) {
    const baseUrl = url+`/api/v1/${type}/${processo}`
    try {
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { error: error.message };
    }
}

// ========================= requisição usuarios ===============================================

export const fetchUsers = async () => {
    const baseUrl = url+'/api/v1/users'
   try {
       const response = await fetch( baseUrl , {
           method: 'GET',
           headers,
       });

       if (!response.ok) {
           throw new Error(`HTTP error! status: ${response.status}`);
       }

       const data = await response.json();
       return data;
   } catch (error) {
       console.error('Erro ao buscar dados:', error);
       throw error;
   }
};

export const saveUser = async (userData, isEditing) => {

    try {
        const baseUrl = isEditing ? `${url}/api/v1/users/${userData.id}` : `${url}/api/v1/users`;
        const method = isEditing ? 'PUT' : 'POST';

        const response = await fetch(baseUrl, {
            method,
            headers,
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados no banco de dados');
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    }
};

export const removeUser = async (user) => {
    const baseUrl = url+`/api/v1/users/${user.Ap_Id}`
    try {
        const updatedUser = {
            ...user,
            status: 'Inativo',
        };

        const response = await fetch( baseUrl, {
            method: 'PUT',
            headers,
            body: JSON.stringify(updatedUser),
        });

        if (!response.ok) {
            throw new Error('Erro ao remover o usuario');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};


// ===================================== reuisição empresas ===================================

export const fetchEmpresas = async () => {
     const baseUrl = url+`/api/v1/empresas`
    try {
        const response = await fetch( baseUrl , {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const  updateEmpresa = async (id, updatedData) => {
    const baseUrl = url+`/api/v1/empresas/${id}`
    try {
        const response = await fetch(baseUrl, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar os dados');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao atualizar os dados:', error);
        throw error;
    }
};

export const addEmpresa = async (newData) => {
    const baseUrl = url+`/api/v1/empresas`
    try {
        const response = await fetch( baseUrl, {
            method: 'POST',
            headers,
            body: JSON.stringify(newData),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    }
};

export const removeEmpresas = async (empresa) => {
    const baseUrl = url+`/api/v1/empresas/${empresa.id}`
    try {
        const updatedEmpresa = {
            ...empresa,
            ativo: 'N',
        };

        const response = await fetch( baseUrl, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(updatedEmpresa),
        });

        if (!response.ok) {
            throw new Error('Erro ao remover a empresa');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

//========================= requisição advogados =================================

export const fetchAdvogados = async () => {
    const baseUrl = url+`/api/v1/advogados`
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const saveAdvogado = async (advogadoData, isEditing) => {
    try {
        const baseUrl  = isEditing ? `${url}/api/v1/advogados/${advogadoData.id}` : `${url}/api/v1/advogados`;
        const method = isEditing ? 'PATCH' : 'POST';

        const response = await fetch(baseUrl, {
            method,
            headers,
            body: JSON.stringify(advogadoData),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados no banco de dados');
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao salvar os dados:', error);
        throw error;
    }
};

export const removeAdvogado = async (advogado) => {
    const baseUrl = url+`/api/v1/advogados/${advogado.id}`
    try {
        const updatedAdvogado = {
            ...advogado,
            ativo: 'N',
        };

        const response = await fetch( baseUrl, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(updatedAdvogado),
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar o status do advogado');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// ==================================== Logs ============================================

export function Logis(payload){
    try {
            const response =  fetch(url + '/api/v1/logs', {
                method: 'POST',
                headers,
                body: JSON.stringify({ route: payload.route, descricao: payload.descricao,  user_id: payload.user_id }),
      });
  
      
      const data = response.json();
      return data
    } catch (error) {
        return error;
    }
};

// =========================================== Uploads ==================================================

export async function UploadPDF(payload) {
    try {
        const formData = new FormData();
        formData.append('pdf', payload.pdf); 
        formData.append('user_id', payload.user_name); 
        if (payload.name) {
            formData.append('document_name', payload.name); 
        }

        const response = await fetch(url + '/api/v1/upload_pdf', {
            method: 'POST',
            body: formData, 
        });

        if (!response.ok) {
            throw new Error(`Erro na requisição: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export const UploadListPDF = async () => {
    const baseUrl = url+`/api/v1/ia_pdf_status`
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};


// ============================================================= Relatorios ====================================


export const MethodsDash = async () => {
    const baseUrl = url+`/api/v1/dashboard_v1`
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

// =========================================== Downloads ==================================================

export async function FecthDownloadsXlsx(payload) {
    try {
        const formData = new FormData();
        formData.append('file', payload.xlsx); 
        formData.append('user_id', payload.user_id); 
        if (payload.name) {
            formData.append('document_name', payload.name); 
        }

        const response = await fetch(url + '/api/v1/upload_xlsx ', {
            method: 'POST',
            body: formData, 
        });

        if (!response.ok) {
            throw new Error(`Erro na requisição: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export const FecthListDownloads = async () => {
    const baseUrl = url+`/api/v1/upload_xlsx_status`
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};


export const DownloadProcessados = async (token) => {
    const baseUrl = url + `/api/v1/upload_xlsx_donwload?token=` + token;

    try {
        const response = await fetch(baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get("Content-Type");

        // Não confie apenas no Content-Type para validar o arquivo
        if (
            contentType &&
            (contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
                contentType.includes("application/octet-stream"))
        ) {
            // Baixar o arquivo como Blob
            const blob = await response.blob();
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = `${token}.xlsx`; // Defina o nome do arquivo para download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Libere o URL após o download
        } else {
            throw new Error("O tipo de arquivo não é compatível para download.");
        }
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

// ========================================== Cnae Gov Api =======================================


export const IBGECnae = async () => {
    const baseUrl = 'https://servicodados.ibge.gov.br/api/v2/cnae/classes'
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.json();
        return data
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};


// =================================================== Mesa ====================================


export const GetDashboardCNPJ = async () => {
    const baseUrl = url + '/api/v1/DashCnpjInfo'
    try {
        const response = await fetch( baseUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.json();
        return data
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};


export const GetDashboardCNPJFilter = async (filters = {}) => {
    const baseUrl = url + '/api/v1/dash_cnpj';

    // Cria um objeto URLSearchParams para adicionar os filtros à URL
    const params = new URLSearchParams();

    // Adiciona apenas os filtros que não estão vazios
    for (const key in filters) {
        if (filters[key]) {
            params.append(key, filters[key]);
        }
    }

    // Concatena os parâmetros à URL base
    const fullUrl = `${baseUrl}?${params.toString()}`;

    try {
        const response = await fetch(fullUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const DownloadExcelLista = async (filters = {}) => {
    const baseUrl = url + `/api/v1/excel_download_cnpj`;

    // Cria um objeto URLSearchParams para adicionar os filtros à URL
    const params = new URLSearchParams();

    // Adiciona apenas os filtros que não estão vazios
    for (const key in filters) {
        if (filters[key]) {
            params.append(key, filters[key]);
        }
    }

    // Concatena os parâmetros à URL base
    const fullUrl = `${baseUrl}?${params.toString()}`;

    try {
        const response = await fetch(fullUrl, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get("Content-Type");

        // Não confie apenas no Content-Type para validar o arquivo
        if (
            contentType &&
            (contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
                contentType.includes("application/octet-stream"))
        ) {
            // Baixar o arquivo como Blob
            const blob = await response.blob();
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = `lista.xlsx`; // Defina o nome do arquivo para download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Libere o URL após o download
        } else {
            throw new Error("O tipo de arquivo não é compatível para download.");
        }
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};