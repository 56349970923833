import React, { useEffect, useState } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import ChartControls from "./ChartControls";
import * as C from './LineChartComponentStyle'; // Importe seus componentes estilizados aqui

// Mapeamento dos meses
const monthMap = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro"
};

// Função para formatar o nome do mês (3 primeiras letras)
const formatMonth = (month) => {
  return monthMap[month].substring(0, 3);
};

// Função para formatar o Tooltip
const CustomTooltip = ({ active, payload, label, viewMode }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc",  color:"#111"}}>
        <p>
          {viewMode === "yearly"
            ? `${label}`
            : `${Object.values(monthMap).find((fullMonth) => fullMonth.startsWith(label))}`}
        </p>
        <p style={{color:"#8884d8"}}>{`Trabalhista: ${payload[0].value.toLocaleString("pt-BR")}`}</p>
        <p style={{color:"#fbc764"}}>{`Precatório: ${payload[1].value.toLocaleString("pt-BR")}`}</p>
      </div>
    );
  }
  return null;
};

const LineChartComponent = ({
  dataYearlyTrabalhista,
  dataMonthlyTrabalhista,
  dataYearlyPrecatorio,
  dataMonthlyPrecatorio
}) => {
  const [viewMode, setViewMode] = useState("yearly"); // "yearly" ou "monthly"
  const [selectedYear, setSelectedYear] = useState(2025);

  const yearsFromYearlyTrabalhista = dataYearlyTrabalhista.map((item) => item.year);
  const yearsFromYearlyPrecatorio = Array.isArray(dataYearlyPrecatorio) 
    ? dataYearlyPrecatorio.map((item) => item.year) 
    : [];

  const availableYears = [...new Set([...yearsFromYearlyTrabalhista, ...yearsFromYearlyPrecatorio])]
    .sort((a, b) => a - b); // Ordena os anos

  // Atualiza o ano selecionado para o primeiro disponível se o atual não existir
  useEffect(() => {
    if (!availableYears.includes(selectedYear)) {
      setSelectedYear(availableYears[0] || new Date().getFullYear());
    }
  }, [availableYears, selectedYear]);

  // Cria um conjunto de dados completo com todos os meses
  const createCompleteMonthlyData = (dataTrabalhista, dataPrecatorio) => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthNumber = index + 1;

      // Dados trabalhistas
      const monthDataTrabalhista = dataTrabalhista.find((item) => {
        const monthMatch = item.month.match(/\d+/);
        return monthMatch && parseInt(monthMatch[0]) === monthNumber;
      });

      // Dados de precatórios
      const monthDataPrecatorio = dataPrecatorio.find((item) => {
        const monthMatch = item.month.match(/\d+/);
        return monthMatch && parseInt(monthMatch[0]) === monthNumber;
      });

      return {
        month: formatMonth(monthNumber),
        processosTrabalhista: monthDataTrabalhista ? monthDataTrabalhista.processos : 0,
        processosPrecatorio: monthDataPrecatorio ? monthDataPrecatorio.processos : 0,
      };
    });
  };

  // Transforma os dados mensais para incluir o nome do mês formatado e preenche meses ausentes
  const formattedDataMonthly = createCompleteMonthlyData(
    dataMonthlyTrabalhista[selectedYear] || [],
    dataMonthlyPrecatorio[selectedYear] || []
  );

  const data = viewMode === "yearly"
    ? availableYears.map((year) => {
        const trabalhista = dataYearlyTrabalhista.find((item) => item.year === year);
        const precatorio = dataYearlyPrecatorio.find((item) => item.year === year);
        return {
          year,
          processosTrabalhista: trabalhista ? trabalhista.processos : 0,
          processosPrecatorio: precatorio ? precatorio.processos : 0,
        };
      })
    : formattedDataMonthly;

  return (
    <C.ChartContainer>
      <ChartControls
        viewMode={viewMode}
        selectedYear={selectedYear}
        availableYears={availableYears}
        onChangeViewMode={setViewMode}
        onChangeYear={setSelectedYear}
      />
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ right: 20 }}>
          <Line type="monotone" dataKey="processosTrabalhista" stroke="#8884d8" name="Trabalhista" />
          <Line type="monotone" dataKey="processosPrecatorio" stroke="#fbc764" name="Precatório" />
          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey={viewMode === "yearly" ? "year" : "month"}
            tickFormatter={viewMode === "monthly" ? (value) => value : undefined}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip viewMode={viewMode} />} />
        </LineChart>
      </ResponsiveContainer>
    </C.ChartContainer>
  );
};

export default LineChartComponent;