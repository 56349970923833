import styled from "styled-components";

export const Card = styled.div`
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: ${({ color }) => color || "#fff"};
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
    max-height: 120px;

    /* ::after, ::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        z-index: -1;
        padding: 3px;
        border-radius: 10px;
        background-image: ${({ color }) => color 
            ? `conic-gradient(from 270deg, ${color} 10%, transparent 25%)` 
            : "#fff"};
    }

    ::before{
        filter: blur(0.5rem);
        opacity: 0.5;
    } */
`;

export const CardTitle = styled.h2`
    font-size: clamp(12px, 0.8vw, 20px); 
    color: #333;
    margin-bottom: 10px;
    width: 100%;
    height: 20px;
    font-weight: 300;
    padding-left: 15px;

`;

export const CardValue = styled.p`
    font-size: clamp(12px, 1vw, 24px); 
    font-weight: bold;
    color: #2c3e50;
    width: 100%;
    white-space: nowrap; 
    width: 100%; 
    text-align: center;
`;

export const Focus = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;

`

export const CardBubble = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
  /* position: absolute; */

  user-select: none; /* Para navegadores modernos */
  -webkit-user-select: none; /* Para Safari */
  -moz-user-select: none; /* Para Firefox */
  -ms-user-select: none; /* Para Internet Explorer/Edge */
  
  p {
    padding: 0.3125rem; /* 5px */
    background-color: ${({ color }) => color || "#fff"};
    border-radius: 50%;
    font-size: 0.625rem; /* 10px */
    width: 22px; /* 20px */
    height: 22px; /* 20px */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  svg{
    fill: #fff
  }
`;