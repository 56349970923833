import styled from "styled-components";

export const ProcessAside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const SearchContainer = styled.form`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;

    button{
        max-width: 9.375rem; /* 150px */
    }
`;

export const InputBody = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3125rem; /* 5px */
    width: 100%;
    border-radius: 1.25rem; /* 20px */
    background: var(--color-grayBlue);
    cursor: text;

    svg{
        font-size: 1rem; /* 16px */
        margin-left: 0.625rem; /* 10px */
    }

    input{
        border-radius: 1.25rem; /* 20px */
        background-color: var(--color-grayBlue);
    }
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem; /* 10px */
  background-color: var(--color-grayBlue);
  border-radius: ${({ $selected }) => ($selected === 'processo'? '0 1.25rem 1.25rem 1.25rem' : '1.25rem')};
  padding: 0.75rem; /* 12px */
  height: 100%;
`;

export const ProcessCard = styled.div`
  padding: 0.625rem; /* 10px */
  border: 1px solid var(--color---color-neutral);
  border-radius: 0.625rem; /* 10px */
  background: var(--color-grayBlue);
`;

export const ProcessTitle = styled.h2`
  font-size: 1.125rem; /* 18px */
  margin: 0;
`;

export const ProcessDetail = styled.p`
  font-size: 1rem; /* 16px */
  margin: 0.3125rem 0 0; /* 5px */
`;

export const NoResultsMessage = styled.div`
  margin-top: 1.25rem; /* 20px */
  padding: 0.625rem; /* 10px */
  border: 1px solid var(--color-red);
  border-radius: 1.25rem; /* 20px */
  background: var(--color-white);
  color: var(--color-red);
  text-align: center;
  font-size: 0.875rem; /* 14px */
  word-break: break-word;
`;

export const HistoryContainer = styled.div`
  margin-top: 1.25rem; /* 20px */
`;

export const HistoryTitle = styled.h2`
  font-size: 1.125rem; /* 18px */
  margin-bottom: 0.625rem; /* 10px */
`;

export const HistoryItem = styled.div`
  cursor: pointer;
  padding: 0.625rem; /* 10px */
  border: 1px solid var(--color-border);
  border-radius: 0.625rem; /* 10px */
  margin-bottom: 0.625rem; /* 10px */
  background: var(--color-grayBlue);
`;

export const HistoryProcessTitle = styled.p`
  font-size: 1rem; /* 16px */
  color: var(--color-black);
`;

export const BoxLoader = styled.div`
  height: auto;
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderResults = styled.div`
  display: flex;
`;

export const NavHeaderResults = styled.p`
  font-size: 1rem; /* 16px */
  font-weight: bold;
  cursor: pointer;
  background: ${({ $selected }) =>
    $selected ? 'var(--color-grayBlue)' : 'var(--color-white)'};
  font-weight: ${({ $selected }) => ($selected ? 'normal' : 'bold')};
  border-radius: 0.625rem 0.625rem 0 0; /* 10px 10px 0 0 */
  padding: 0.75rem 0.75rem 0.375rem 0.75rem; /* 12px 12px 6px 12px */
`;

export const ProcessoItem = styled.div`
  background-color: ${({ isActive }) => (isActive ? 'var(--color-grayBlue)' : 'var(--color-white)')};
  padding: 0.625rem; /* 10px */
  border-radius: 0.625rem; /* 10px */
  transition: background-color 0.3s ease;
  cursor: pointer;
  
  &:hover {
    background-color: var(--color-lightGray);
  }
`;