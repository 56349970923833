import React, { useEffect, useState } from "react";
import "../../../styles/variables.css";
import * as C from "./UploadsStyles";
import Button from "../../../components/button/Button";
import Input from "../../../components/inputs/Input";
import { UploadListPDF, UploadPDF } from "../../../service/ServiceApi";
import SearchBar from '../../../components/search/Search';
import { BsSearch } from "react-icons/bs";
import { FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const Uploads = ({    search, 
    setSearch, handleCPFSearch, handleSearch, handleSearchClick, onNavLinkClick }) => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState("Selecione ou arraste um arquivo PDF");
    const [isValid, setIsValid] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de busca
    

    const fetchData = async () => {
        try {
            const data = await UploadListPDF();

            const formattedData = Object.entries(data).map(([fileName, item], index) => ({
                id: index + 1,
                processo: fileName || "Desconhecido",
                nome: item.user || "Usuário Desconhecido",
                dataInclusao: new Date(item.data_create),
                dataFinalizacao: item.time_look || "",
                progresso: item.status || "Indefinido",
                motivo: item.motivo || "",
                status: item.statusSn || "",
            }));

            // Ordena por data de inclusão mais recente
        // Função para verificar se o progresso é "processando", "Em Processamento" ou "Processando"
        const isProcessing = (status) =>
            ["processando", "Em Processamento", "Processando"].includes(status);

        // Ordena os dados
        const sortedData = formattedData.sort((a, b) => {
            // Prioriza os itens com progresso de "processando"
            if (isProcessing(a.progresso) && !isProcessing(b.progresso)) {
                return -1;
            }
            if (isProcessing(b.progresso) && !isProcessing(a.progresso)) {
                return 1;
            }
            // Se ambos estão em "processando", ordena do mais recente para o mais antigo
            if (isProcessing(a.progresso) && isProcessing(b.progresso)) {
                return b.dataInclusao - a.dataInclusao;
            }
            // Para os demais itens, ordena do mais recente para o mais antigo
            return b.dataInclusao - a.dataInclusao;
        });

            setTableData(sortedData);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, 5 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    const MAX_FILE_SIZE = 300 * 1024 * 1024; 

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
    
        // Filtrar arquivos válidos (PDF e tamanho <= 300 MB)
        const validFiles = selectedFiles.filter(file => 
            file.type === "application/pdf" && file.size <= MAX_FILE_SIZE
        );
    
        // Verificar arquivos inválidos (não PDF)
        const invalidTypeFiles = selectedFiles.filter(file => 
            file.type !== "application/pdf"
        );
    
        // Verificar arquivos acima do limite de tamanho
        const oversizedFiles = selectedFiles.filter(file => 
            file.size > MAX_FILE_SIZE
        );
    
        // Mensagens de erro para arquivos inválidos
        if (invalidTypeFiles.length > 0) {
            if (invalidTypeFiles.length === 1) {
                setError(`O arquivo "${invalidTypeFiles[0].name}" não é um PDF válido.`);
            } else {
                setError(`${invalidTypeFiles.length} arquivos não são PDFs válidos.`);
            }
            setIsValid(false);
            return; // Impede a execução do restante da função
        }
        
        // Mensagens de erro para arquivos acima do limite de tamanho
        if (oversizedFiles.length > 0) {
            if (oversizedFiles.length === 1) {
                setError(`O arquivo "${oversizedFiles[0].name}" excede o limite de 300 MB.`);
            } else {
                setError(`${oversizedFiles.length} arquivos excedem o limite de 300 MB.`);
            }
            setIsValid(false);
            return; // Impede a execução do restante da função
        }
        
        // Verificar se há arquivos válidos
        if (validFiles.length === 0) {
            setError("Nenhum arquivo válido selecionado.");
            setIsValid(false);
            return;
        }
    
        // Remover arquivos duplicados dentro do próprio lote
        const uniqueFiles = Array.from(new Map(validFiles.map(file => [file.name, file])).values());
    
        // Filtrar arquivos que já estão na lista de envio
        const newFiles = uniqueFiles.filter(file => !files.some(f => f.name === file.name));
    
        const statusEmProcessamento = [
            "Processando",
            "Em Processamento",
            "Na Fila de Processamento",
            "Aguardando o Processamento",
            "Aguardando"
        ];
    
        // Verificar se o arquivo já foi enviado anteriormente e está em processamento
        const alreadySentFiles = newFiles.filter(file => 
            tableData.some(item => 
                item.processo === file.name && statusEmProcessamento.includes(item.progresso)
            )
        );
    
        if (alreadySentFiles.length > 0) {
            setError(`O arquivo "${alreadySentFiles[0].name}" já foi enviado anteriormente`);
            setIsValid(false);
            return;
        }
    
        if (newFiles.length > 0) {
            setFiles(prevFiles => {
                const updatedFiles = [...prevFiles, ...newFiles];
    
                // Atualizar a mensagem de sucesso corretamente
                setError(updatedFiles.length === 1 ? `Arquivo "${updatedFiles[0].name}" selecionado com sucesso.` : `${updatedFiles.length} arquivos selecionados com sucesso.`);
    
                return updatedFiles;
            });
    
            setIsValid(true);
        } else {
            setError("Arquivo já adicionado ou inválido.");
            setIsValid(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
    
        // Filtrar arquivos válidos (PDF e tamanho <= 300 MB)
        const validFiles = droppedFiles.filter(file => 
            file.type === "application/pdf" && file.size <= MAX_FILE_SIZE
        );
    
        // Verificar arquivos inválidos (não PDF)
        const invalidTypeFiles = droppedFiles.filter(file => 
            file.type !== "application/pdf"
        );
    
        // Verificar arquivos acima do limite de tamanho
        const oversizedFiles = droppedFiles.filter(file => 
            file.size > MAX_FILE_SIZE
        );
    
        if (invalidTypeFiles.length > 0) {
            if (invalidTypeFiles.length === 1) {
                setError(`O arquivo "${invalidTypeFiles[0].name}" não é um PDF válido.`);
            } else {
                setError(`${invalidTypeFiles.length} arquivos não são PDFs válidos.`);
            }
            setIsValid(false);
            return; // Impede a execução do restante da função
        }
        
        // Mensagens de erro para arquivos acima do limite de tamanho
        if (oversizedFiles.length > 0) {
            if (oversizedFiles.length === 1) {
                setError(`O arquivo "${oversizedFiles[0].name}" excede o limite de 300 MB.`);
            } else {
                setError(`${oversizedFiles.length} arquivos excedem o limite de 300 MB.`);
            }
            setIsValid(false);
            return; // Impede a execução do restante da função
        }
        
        // Verificar se há arquivos válidos
        if (validFiles.length === 0) {
            setError("Nenhum arquivo válido selecionado.");
            setIsValid(false);
            return;
        }
    
        // Remover arquivos duplicados dentro do próprio lote
        const uniqueFiles = Array.from(new Map(validFiles.map(file => [file.name, file])).values());
    
        // Filtrar arquivos que já estão na lista de envio
        const newFiles = uniqueFiles.filter(file => !files.some(f => f.name === file.name));
    
        const statusEmProcessamento = [
            "Processando",
            "Em Processamento",
            "Na Fila de Processamento",
            "Aguardando o Processamento",
            "Aguardando"
        ];
    
        // Verificar se o arquivo já foi enviado anteriormente e está em processamento
        const alreadySentFiles = newFiles.filter(file => 
            tableData.some(item => 
                item.processo === file.name && statusEmProcessamento.includes(item.progresso)
            )
        );
    
        if (alreadySentFiles.length > 0) {
            setError(`O arquivo "${alreadySentFiles[0].name}" já foi enviado anteriormente`);
            setIsValid(false);
            return;
        }
    
        if (newFiles.length > 0) {
            setFiles(prevFiles => {
                const updatedFiles = [...prevFiles, ...newFiles];
    
                // Atualizar a mensagem de sucesso corretamente
                setError(updatedFiles.length === 1 ? `Arquivo "${updatedFiles[0].name}" selecionado com sucesso.` : `${updatedFiles.length} arquivos selecionados com sucesso.`);
    
                return updatedFiles;
            });
    
            setIsValid(true);
        } else {
            setError("Arquivo já adicionado ou inválido.");
            setIsValid(false);
        }
    };
    


    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (files.length === 0) {
            setIsValid(false);
            setError("Nenhum arquivo selecionado.");
            return;
        }
    
        setIsUploading(true);
    
        try {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const payload = {
                    user_name: JSON.parse(localStorage.getItem("user_token"))?.user?.nome,
                    name: file.name,
                    pdf: file,
                };
    
                // Atualiza a mensagem de erro com o progresso atual
                setError(`Enviando arquivo ${i + 1}/${files.length}: ${file.name}`);
    
                // Envia o arquivo atual
                await UploadPDF(payload);
    
                // Adiciona um intervalo de tempo antes de enviar o próximo arquivo
                if (i < files.length - 1) { // Não espera após o último arquivo
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // 500ms de intervalo
                }
            }
    
            // Mensagem de sucesso após o término do upload
            setError("Upload concluído com sucesso!");
    
        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            setError("Erro ao fazer upload. Tente novamente.");
            setIsValid(false);
        } finally {
            setIsUploading(false);
            setIsValid(false);
            fetchData();
    
            // Limpa o estado dos arquivos e mensagens de erro após 1 segundo
            setTimeout(() => {
                setFiles([]);
                setError("Selecione ou arraste arquivos PDF");
                setIsValid(false);
            }, 1000);
        }
    };

    const formatProcesso = (processo) => {
        // Remove a extensão .pdf, se houver
        let formatted = processo.endsWith('.pdf') ? processo.slice(0, -4) : processo;
        
        // Remove qualquer conteúdo entre parênteses, incluindo os próprios parênteses
        formatted = formatted.replace(/\s*\([^)]*\)/g, '');
        
        return formatted.trim();
    };

    const formatProgress = (progress) => {
        switch (progress) {
            case "Na Fila de Processamento":
            case "Aguardando o Processamento":
                return "Aguardando";
            case "Processamento Concluido":
            case "Concluido":
                return "Concluído";
            case "Erro no PDF":
            case "Erro  PDF":
                return "Erro PDF";
            case "Erro AIA":
                return "Erro AIA"    
            default:
                return progress || "Indefinido";
        }
    };

        const [currentPage, setCurrentPage] = useState(1);
        const usersPerPage = 40;
        
        const normalizeString = (str) => {
            return str
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, "")  // Remove acentos
                .replace(/ç/g, "c")               // Substitui "ç" por "c"
                .replace(/ã|á|â|à/g, "a")         // Substitui "ã", "á", "â", "à" por "a"
                .replace(/õ|ó|ô|ò/g, "o")         // Substitui "õ", "ó", "ô", "ò" por "o"
                .replace(/é|ê|è/g, "e")           // Substitui "é", "ê", "è" por "e")
                .replace(/í|ì|î/g, "i")           // Substitui "í", "ì", "î" por "i"
                .replace(/ú|ù|û/g, "u")           // Substitui "ú", "ù", "û" por "u"
                .toLowerCase();                   // Converte para minúsculas
        };
        
        const handleProcess = (term) => {
            setSearchTerm(term); // Atualiza o termo de busca
            setCurrentPage(1);
        };
    
        const filteredData = tableData.filter((item) =>
            normalizeString(item.processo || "").includes(normalizeString(searchTerm)) || // Filtra por processo
            normalizeString(item.nome || "").includes(normalizeString(searchTerm)) || // Filtra por nome
            normalizeString(item.progresso || "").includes(normalizeString(searchTerm)) // Filtra por progresso
        );
        const indexOfLastUser = currentPage * usersPerPage;
        const indexOfFirstUser = indexOfLastUser - usersPerPage;
        const currentItems = filteredData.slice(indexOfFirstUser, indexOfLastUser);
        const totalPages = Math.ceil(filteredData.length / usersPerPage);
        
        const handleNextPage = () => {
            setCurrentPage((prevPage) => prevPage + 1);
        };
        
        const handlePreviousPage = () => {
            setCurrentPage((prevPage) => prevPage - 1);
        };
        
        const handlePageChange = (pageNumber) => {
            setCurrentPage(pageNumber);
        };
    
        const generatePageNumbers = () => {
            const maxPageNumbers = 5; // Quantidade máxima de números de página a serem exibidos
            let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
            let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
        
            if (endPage - startPage < maxPageNumbers - 1) {
                startPage = Math.max(1, endPage - maxPageNumbers + 1);
            }
        
            return [...Array(endPage - startPage + 1)].map((_, i) => startPage + i);
        };

    const formatInput = (value) => {
        const onlyNumbers = value.replace(/\D/g, '');
        let formattedValue = value;
    
        if (value.startsWith('(')) {
            if (onlyNumbers.length === 11) {
                // Telefone celular
                formattedValue = onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            } else if (onlyNumbers.length === 10) {
                // Telefone fixo
                formattedValue = onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            }
        } else if (onlyNumbers.length === 11) {
            // CPF
            formattedValue = onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (onlyNumbers.length === 14) {
            // CNPJ
            formattedValue = onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else if (onlyNumbers.length === 20) {
            // Processo
            formattedValue = onlyNumbers.replace(/(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/, '$1-$2.$3.$4.$5.$6');
        }
    
        return formattedValue;
    };
    
    // Regex Patterns
    const patterns = {
        trabalhista: /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/,
        precatorio: /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/,
        cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{11}$/,
        cnpj: /^\d{14}$|(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2})/,
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        telefone: /^\(\d{2}\)\s?\d{4,5}-\d{4}$/
    };
    
    // Função para determinar o tipo de busca
    const determineSearchType = (value) => {
        if (patterns.trabalhista.test(value)) return 'trabalhista';
        if (patterns.precatorio.test(value)) return 'precatorio';
        if (patterns.cpf.test(value)) return 'cpf';
        if (patterns.cnpj.test(value)) return 'cnpj';
        if (patterns.email.test(value)) return 'email';
        if (patterns.telefone.test(value)) return 'telephone';
        return 'nome'; // Presume nome se não encaixar nas categorias acima
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const trimmedSearch = search.trim();
        if (!trimmedSearch) return;

        onNavLinkClick("Consultas")
        const type = determineSearchType(trimmedSearch);
        const cleanSearch = type === 'telephone' ? formatInput(trimmedSearch).replace(/[^\d]/g, '') : trimmedSearch;

        if (type === 'cpf' || type === 'cnpj' || type === 'email' || type === 'telephone'|| type === 'nome') {
            handleCPFSearch(cleanSearch, type);
        } else {
            handleSearch(cleanSearch, type);
        }
    };

     // Função para normalizar e remover acentos/diacríticos de uma string



    return (
        <C.ArticleConteiner>
            <C.SearchContainer onSubmit={onSubmit}>
                <C.InputBody onClick={handleSearchClick}>
                    <BsSearch />
                    <Input
                        type="text"
                        placeholder="Busca por CPF, nome, telefone com ( ), email, processo, CNPJ ou empresa"
                        id="input_search"
                        autocomplete="off"
                        value={search}
                        onChange={(e) => setSearch(formatInput(e.target.value))}
                    />
                </C.InputBody>
                <Button Text="Consultar" type="submit" />
            </C.SearchContainer>
            <C.UploadContainer>
                <C.FormBox onSubmit={handleSubmit} onDrop={handleDrop} onDragOver={handleDragOver}>
                    <C.InputFileBox>
                        <input
                            type="file"
                            id="pdf-upload"
                            onChange={handleFileChange}
                            autoComplete="off"
                            className="file-input"
                            multiple
                        />
                        <C.CircleLoppingBox>
                            <C.CircleLopping htmlFor="pdf-upload" $isValid={isValid}>
                                <C.LabelInitBox htmlFor="pdf-upload" $isValid={isValid}>
                                {error}
                                </C.LabelInitBox>
                            </C.CircleLopping>
                        </C.CircleLoppingBox>
                    </C.InputFileBox>
                    {isUploading ? (
                        <C.animateSpan>Carregando...</C.animateSpan>
                    ) : (
                        <Button Text="Enviar" type="submit" />
                    )}
                </C.FormBox>
            </C.UploadContainer>
            {tableData.length > 0 && (
                <C.UploadContainer>
                    <C.FormTable>
                        <C.Envelope>
                        <C.Header>
                            <C.HeaderTitle>Ordens</C.HeaderTitle>
                                <C.MovSearchContainer>
                                    <SearchBar onSearch={handleProcess} placeholder="Filtrar processos..." />
                                </C.MovSearchContainer>
                        </C.Header>
                            <C.Table>
                                {currentItems.length > 0 &&( 
                                <thead>
                                    <tr>
                                        <th>Processos</th>
                                        <th>Nome</th>
                                        <th>Data de Inclusão</th>
                                        <th>Tempo de Execução</th>
                                        <th>Progresso</th>
                                        <th>Motivo</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>)}
                                <tbody>
                                    {currentItems.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.processo ? formatProcesso(item.processo) : ""}</td>
                                            <td>{item.nome || ""}</td>
                                            <td>{item.dataInclusao.toLocaleString("pt-BR")}</td>
                                            <td>{item.dataFinalizacao || ""}</td>
                                            <td>
                                                {item.progresso && (
                                                    <C.ProgressIndicator $progress={item.progresso}>
                                                        {formatProgress(item.progresso)}
                                                    </C.ProgressIndicator>
                                                )}
                                            </td>
                                            <td>{item.motivo || ""}</td>
                                            <td>
                                                {item.status && (
                                                    <C.Status $status={item.status}>
                                                        {item.status || ""}
                                                    </C.Status>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </C.Table>
                                        {totalPages > 1 && (
                                            <C.PaginationContainer>
                                                <C.PaginationButton
                                                    onClick={() => handlePageChange(1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    <FaAnglesLeft />
                                                </C.PaginationButton>
                                                <C.PaginationButton
                                                    onClick={handlePreviousPage}
                                                    disabled={currentPage === 1}
                                                >
                                                    <FaAngleLeft />
                                                </C.PaginationButton>
                                                {generatePageNumbers().map((pageNumber) => (
                                                    <C.PaginationButton
                                                        key={pageNumber}
                                                        onClick={() => handlePageChange(pageNumber)}
                                                        $active={pageNumber === currentPage}
                                                    >
                                                        {pageNumber}
                                                    </C.PaginationButton>
                                                ))}
                                                <C.PaginationButton
                                                    onClick={handleNextPage}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    <FaAngleRight />
                                                </C.PaginationButton>
                                                <C.PaginationButton
                                                    onClick={() => handlePageChange(totalPages)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    <FaAnglesRight />
                                                </C.PaginationButton>
                                            </C.PaginationContainer>
                                        )}
                        </C.Envelope>
                    </C.FormTable>
                </C.UploadContainer>
            )}
        </C.ArticleConteiner>
    );
};

export default Uploads;
