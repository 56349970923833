import React, { useCallback, useEffect, useState } from "react";
import "../../../styles/variables.css";
import * as C from "./RelatoriosStyles"
import Cards from "../../../components/cards/Cards";
import GraficsList from "../../../components/grafics/GraficoBar";
import MapChart from "../../../components/maps/MapaChart";
import EstadoBarChart from "../../../components/graphs/durationBarChar/DurationBarChar";
import FaseBarChart from "../../../components/graphs/verticalFaseBarChar/FaseBarChart";
import Input from "../../../components/inputs/Input";
import { BsSearch } from "react-icons/bs";
import Button from "../../../components/button/Button";
import { FaUserPlus } from "react-icons/fa6";
import { SiGoogledocs } from "react-icons/si";
import { PiPencilSimpleLight } from "react-icons/pi";
import { MdFilterList } from "react-icons/md";
import OutGraficsList from "../../../components/outgraphsCopy/GraficoBar";
import { MethodsDash } from "../../../service/ServiceApi";
import { MdDeleteOutline } from "react-icons/md";
import TableComponent from "./TableComponent";
import LineChartComponent from "../../../components/graphs/lineCharts/LineChartComponent";



const Relatorios = ({    search, 
    setSearch, handleCPFSearch, handleSearch, handleSearchClick, onNavLinkClick }) => {
    const [selectedState, setSelectedState] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [totalProcessos, setTotalProcessos] = useState(0);
    const [processosValorTotal, setProcessosValorTotal] = useState(0);
    const [processosTransitados, setProcessosTransitados] = useState(0);
    const [processosDistribuido, setProcessosDistribuido] = useState(0);
    const [processosArquivados, setProcessosArquivados] = useState(0);
    const [dadosConsolidadosCards, setDadosconsolidadosCards] = useState([]);
    const [instancia, setInstancia] = useState(0);
    const [stateInstancia, setStateInstancia] = useState([]);
    const [trts, setTrts] = useState([]);
    const [callEstado, setCallEstado] = useState([]);
    const [callEstadoValor, setCallEstadoValor] = useState([]);
    const [ordenar, setOrdenar] = useState({});
    const [dataYearlyTrabalhista, setDataYearlyTrabalhista] = useState([]);
    const [dataMonthlyTrabalhista, setDataMonthlyTrabalhista] = useState({});
    const [dataYearlyPrecatorio, setDataYearlyPrecatorio] = useState({}); 
    const [dataMonthlyPrecatorio, setDataMonthlyPrecatorio] = useState({});
    const [tableMathData, setTableMathData] = useState({});    

    const handleOrdenarClick = (key) => {
        setOrdenar((prev) => ({
            ...prev,
            [key]: !prev[key], 
        }));
    };

    const [processos30D , setProcessos30D] = useState({
        temp:[]
    });

    const [processDropTotal, setProcessDropTotal] = useState({
        temp: []
    });

    const [geralPorEstado, setGeralPorEstado] = useState({
        temp: []
    });

    const [renderGeralPorEstado, setRenderGeralPorEstado] = useState({
        temp: []
    });

    const [duracao, setDurcao] = useState({
        temp_acordao: [],
        temp_definitivo: [],
        temp_sentenca: []
      });

      


    const fetchData = useCallback(async () => {
        try {
            const data = await MethodsDash();
            
            const TotalProcessos = data.geral[0]?.total_de_processos || 0;
            const Transitados = data.geral[0]?.transito_em_julgado_nos_ultimos_30_dias || 0;
            const Distribuido = data.geral[0]?.distribuido_nos_ultimos_30_dias || 0;
            const Arquivados = data.geral[0]?.arquivados_nos_ultimos_30_dias|| 0;

            const trt = [
                { nome: "TRT 1", estado: "Rio de Janeiro", trt: 1 },
                { nome: "TRT 2", estado: "São Paulo", trt: 2 },
                { nome: "TRT 3", estado: "Minas Gerais", trt: 3 },
                { nome: "TRT 4", estado: "Rio Grande do Sul", trt: 4 },
                { nome: "TRT 5", estado: "Bahia", trt: 5 },
                { nome: "TRT 6", estado: "Pernambuco", trt: 6 },
                { nome: "TRT 7", estado: "Ceará", trt: 7 },
                { nome: "TRT 8", estado: "Pará e Amapá", trt: 8 },
                { nome: "TRT 9", estado: "Paraná", trt: 9 },
                { nome: "TRT 10", estado: "Distrito Federal e Tocantins", trt: 10 },
                { nome: "TRT 11", estado: "Amazonas e Roraima", trt: 11 },
                { nome: "TRT 12", estado: "Santa Catarina", trt: 12 },
                { nome: "TRT 13", estado: "Paraíba", trt: 13 },
                { nome: "TRT 14", estado: "Rondônia e Acre", trt: 14 },
                { nome: "TRT 15", estado: "São Paulo", trt: 15 },
                { nome: "TRT 16", estado: "Maranhão", trt: 16 },
                { nome: "TRT 17", estado: "Espírito Santo", trt: 17 },
                { nome: "TRT 18", estado: "Goiás", trt: 18 },
                { nome: "TRT 19", estado: "Alagoas", trt: 19 },
                { nome: "TRT 20", estado: "Sergipe", trt: 20 },
                { nome: "TRT 21", estado: "Rio Grande do Norte", trt: 21 },
                { nome: "TRT 22", estado: "Piauí", trt: 22 },
                { nome: "TRT 23", estado: "Mato Grosso", trt: 23 },
                { nome: "TRT 24", estado: "Mato Grosso do Sul", trt: 24 },
              ];

            setTotalProcessos(TotalProcessos);
            setProcessosTransitados(Transitados);
            setProcessosDistribuido(Distribuido);
            setProcessosArquivados(Arquivados);

            setRenderTotalProcessos(TotalProcessos);
            setRenderProcessosTransitados(Transitados);
            setRenderProcessosDistribuido(Distribuido);
            setRenderProcessosArquivados(Arquivados);

            // Atualiza o estado com o objeto 'instancia'

            if (data && data.instancia) {
                setInstancia(data.instancia);
                setRenderInstancia(data.instancia) 
            }

            if(data && data.instancia){
                const estadoMap = new Map();

            // Inicializa o mapa com todos os estados e valores zerados
            trt.forEach(trtInfo => {
                if (!estadoMap.has(trtInfo.estado)) {
                    estadoMap.set(trtInfo.estado, {
                        nome: trtInfo.estado,
                        primeira: 0,
                        segunda: 0,
                        terceira: 0,
                    });
                }
            });

            // Processa os dados recebidos e acumula os valores por estado
            data.instancia_por_trt.forEach(item => {
                // Itera sobre todos os TRTs para encontrar correspondências com o estado atual
                trt.forEach(trtInfo => {
                    if (trtInfo.trt === item.trt) {
                        const estadoInfo = estadoMap.get(trtInfo.estado);

                        if (estadoInfo) {
                            estadoInfo.primeira += item.primeira || 0;
                            estadoInfo.segunda += item.segunda || 0;
                            estadoInfo.terceira += item.terceira || 0;
                        }
                    }
                });
            });
            const estados = Array.from(estadoMap.values());

            // Ordena os estados pelo valor total da instância (primeira, segunda e terceira)
            const sortedEstados = estados.sort(
                (a, b) =>
                    b.primeira + b.segunda + b.terceira -
                    (a.primeira + a.segunda + a.terceira)
            );

            setStateInstancia(sortedEstados)
            }

            if (data && data.temp_acordao && data.temp_definitivo && data.temp_sentenca) {
                setDurcao({
                  temp_acordao: data.temp_acordao,
                  temp_definitivo: data.temp_definitivo,
                  temp_sentenca: data.temp_sentenca
                });
                
              }

            if(data && data.status_valor_30d){

                setProcessos30D({
                    temp: data.status_valor_30d  
                }); 
            }

            if(data && data.status_valor){

                setProcessDropTotal({
                    temp: data.status_valor  
                }); 
            }

            if (data && data.geral_por_estado) {
                // Soma os valores do campo total_valor
                const somaTotalValor = data.geral_por_estado.reduce((sum, item) => {
                    return sum + (parseFloat(item.total_valor) || 0);
                }, 0);
            
                setProcessosValorTotal(somaTotalValor);

                setRenderProcessosValorTotal(somaTotalValor);
            
                // Cria um mapa para acumular valores por TRT
                const trtMap = new Map();
            
                // Inicializa o mapa com todos os TRTs com valor zero
                trt.forEach(trtInfo => {
                    trtMap.set(trtInfo.nome, {
                        nome: `${trtInfo.nome}ª Região`,
                        valor: 0,
                        estado: trtInfo.estado
                    });
                });
            
                // Processa os dados recebidos e acumula os valores por TRT
                data.geral_por_estado.forEach(item => {
                    // eslint-disable-next-line eqeqeq
                    const trtsCorrespondentes = trt.filter(t => t.trt == item.trt);
            
                    trtsCorrespondentes.forEach(trtInfo => {
                        const existing = trtMap.get(trtInfo.nome);
            
                        if (existing) {
                            // Soma o valor do total_processo
                            existing.valor += item.total_processo || 0;
                        }
                    });
                });
            
                // Converte o mapa em um array de TRTs
                const trts = Array.from(trtMap.values());
            
                setTrts(trts);
            
                setGeralPorEstado({
                    temp: data.geral_por_estado
                });
            }

            if (data && data.geral_por_estado) {
                // Cria um mapa para acumular valores por estado
                const estadoMap = new Map();
            
                // Inicializa o mapa com todos os estados e valores zerados
                trt.forEach(trtInfo => {
                    if (!estadoMap.has(trtInfo.estado)) {
                        estadoMap.set(trtInfo.estado, {
                            nome: trtInfo.estado,
                            valor: 0,
                            monetario: 0, 
                        });
                    }
                });
            
                // Processa os dados recebidos e acumula os valores por estado
                data.geral_por_estado.forEach(item => {
                    // Itera sobre todos os TRTs para encontrar correspondências com o estado atual
                    trt.forEach(trtInfo => {
                        if (trtInfo.estado === item.estado) {
                            const estadoInfo = estadoMap.get(trtInfo.estado);
                            const estadoComValor = estadoMap.get(trtInfo.estado);
            
                            if (estadoInfo) {
                                estadoInfo.valor += item.total_processo || 0;
                                estadoComValor.monetario += parseFloat(item.total_valor) || 0; // Acumula total_valor
                            }
                        }
                    });
                });
            
                // Converte o mapa em um array de estados
                const estados = Array.from(estadoMap.values());
                setCallEstado(estados.sort((a, b) => b.valor - a.valor));

            }

            if (data && data.geral_por_estado) {
                // Cria um mapa para acumular valores de total_valor por estado
                const totalValorEstadoMap = new Map();
            
                // Inicializa o mapa com todos os estados e valores zerados
                trt.forEach(trtInfo => {
                    if (!totalValorEstadoMap.has(trtInfo.estado)) {
                        totalValorEstadoMap.set(trtInfo.estado, {
                            nome: trtInfo.estado,
                            valor: 0, // Inicializa com zero
                        });
                    }
                });
            
                // Processa os dados recebidos e acumula os valores de total_valor por estado
                data.geral_por_estado.forEach(item => {
                    // Itera sobre todos os TRTs para encontrar correspondências com o estado atual
                    trt.forEach(trtInfo => {
                        if (trtInfo.estado === item.estado) {
                            const estadoInfo = totalValorEstadoMap.get(trtInfo.estado);
            
                            if (estadoInfo) {
                                estadoInfo.valor += parseFloat(item.total_valor) || 0; // Acumula total_valor
                            }
                        }
                    });
                });
            
                // Converte o mapa em um array de estados e exibe no console
                const totalValoresEstados = Array.from(totalValorEstadoMap.values());
                setCallEstadoValor(totalValoresEstados.sort((a, b) => b.valor - a.valor));
            }
            if (data && data.trt_ultimos_status_30_dias && data.geral_por_estado) {
                // Cria um mapa para acumular os valores por estado
                const statusEstadoMap = new Map();
            
                // Inicializa o mapa com todos os estados e valores zerados
                trt.forEach((trtInfo) => {
                    if (!statusEstadoMap.has(trtInfo.estado)) {
                        statusEstadoMap.set(trtInfo.estado, {
                            nome: trtInfo.estado,
                            arquivados: 0, // Inicializa com zero
                            distribuido: 0, // Inicializa com zero
                            transitados: 0, // Inicializa com zero
                            total_processo: 0, // Inicializa com zero
                            total_valor: 0, // Inicializa com zero
                        });
                    }
                });
            
                // Processa os dados recebidos e acumula os valores por estado
                data.trt_ultimos_status_30_dias.forEach((item) => {
                    trt.forEach((trtInfo) => {
                        if (String(trtInfo.trt) === String(item.trt)) {
                            const estadoInfo = statusEstadoMap.get(trtInfo.estado);
            
                            if (estadoInfo) {
                                estadoInfo.arquivados += parseFloat(item.arquivados) || 0; // Acumula arquivados
                                estadoInfo.distribuido += parseFloat(item.distribuido) || 0; // Acumula distribuído
                                estadoInfo.transitados += parseFloat(item.transitados) || 0; // Acumula transitados
                            }
                        }
                    });
                });
            
                // Atualiza o mapa com os valores de total_processo e total_valor de geral_por_estado
                data.geral_por_estado.forEach((item) => {
                    trt.forEach((trtInfo) => {
                        if (trtInfo.estado === item.estado) {
                            const estadoInfo = statusEstadoMap.get(trtInfo.estado);
            
                            if (estadoInfo) {
                                estadoInfo.total_processo += parseFloat(item.total_processo) || 0; // Soma total_processo
                                estadoInfo.total_valor += parseFloat(item.total_valor.replace(/,/g, '')) || 0; // Soma total_valor
                            }
                        }
                    });
                });
            
                // Converte o mapa em um array de estados e exibe no console
                const statusEstados = Array.from(statusEstadoMap.values());
            
                // Ordena por arquivados, caso necessário
                setDadosconsolidadosCards(statusEstados.sort((a, b) => b.arquivados - a.arquivados));
            }

            if (data && data.total_mensal_grafico_trabalhista && data.total_mensal_grafico_precatorio) {
                // Função para transformar os dados
                const transformData = (data) => {
                  const yearlyData = {};
                  const monthlyData = {};
          
                  data.forEach(item => {
                    const year = item.ano;
                    const month = item.mes;
                    const total = item.total;
          
                    // Acumula os totais por ano
                    if (!yearlyData[year]) {
                      yearlyData[year] = 0;
                    }
                    yearlyData[year] += total;
          
                    // Organiza os dados por mês para cada ano
                    if (!monthlyData[year]) {
                      monthlyData[year] = [];
                    }
                    monthlyData[year].push({ month: `Mês ${month}`, processos: total });
                  });
          
                  return { yearlyData, monthlyData };
                };
          
                // Transforma os dados trabalhistas
                const { yearlyData: yearlyTrabalhista, monthlyData: monthlyTrabalhista } = transformData(data.total_mensal_grafico_trabalhista);
                const formattedYearlyTrabalhista = Object.keys(yearlyTrabalhista).map(year => ({
                  year: parseInt(year),
                  processos: yearlyTrabalhista[year]
                }));
          
                // Transforma os dados de precatórios
                const { yearlyData: yearlyPrecatorio, monthlyData: monthlyPrecatorio } = transformData(data.total_mensal_grafico_precatorio);
                const formattedYearlyPrecatorio = Object.keys(yearlyPrecatorio).map(year => ({
                  year: parseInt(year),
                  processos: yearlyPrecatorio[year]
                }));
          
                // Atualiza o estado com os dados transformados
                setDataYearlyTrabalhista(formattedYearlyTrabalhista);
                setDataMonthlyTrabalhista(monthlyTrabalhista);
                setDataYearlyPrecatorio(formattedYearlyPrecatorio);
                setDataMonthlyPrecatorio(monthlyPrecatorio);
              }

            if (data && data.total_mensal_anual){
                const meses = [
                    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                ];
                
                const transformarDados = (totalMensalAnual, 
                    totalMensalAnualArquivado, 
                    totalMensalAnualdistribuido,
                    totalMensalAnuavalor,
                    totalMensalAnuaTransitado) => {
                    return totalMensalAnual.map(item => {
                        // Encontrar o item correspondente em totalMensalAnualArquivado
                        const arquivado = totalMensalAnualArquivado.find(
                            arquivadoItem => arquivadoItem.ano === item.ano && arquivadoItem.mes === item.mes
                        );

                        const distribuido = totalMensalAnualdistribuido.find(
                            distribuidoItem => distribuidoItem.ano === item.ano && distribuidoItem.mes === item.mes
                        );

                        const anualValor = totalMensalAnuavalor.find(
                            distribuidoItem => distribuidoItem.ano === item.ano && distribuidoItem.mes === item.mes
                        );

                        const julgado = totalMensalAnuaTransitado.find(
                            julgadoItem => julgadoItem.ano === item.ano && julgadoItem.mes === item.mes
                        );
    
                        return {
                            year: item.ano,
                            month: meses[item.mes - 1], 
                            totalProcesses: item.total,
                            totalValue: anualValor ? anualValor.total : 0, 
                            distributed: distribuido ? distribuido.total : 0, 
                            archived: arquivado ? arquivado.total : 0, 
                            judged: julgado? julgado.total :0, 
                        };
                    });
                };
    
                // Transformando os dados
                const dadosTransformados = 
                transformarDados(
                    data.total_mensal_anual, 
                    data.total_mensal_anual_arquivado,
                    data.total_mensal_anual_distribuido,
                    data.total_mensal_anual_valor, 
                    data.total_mensal_anual_transitado);
    
                // console.log('Dados transformados:', dadosTransformados);
                setTableMathData(dadosTransformados)
            }
            
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /// funçaõ para mapear os trts

    const trt = [
        { nome: "TRT 1", estado: "Rio de Janeiro", trt: 1 },
        { nome: "TRT 2", estado: "São Paulo", trt: 2 },
        { nome: "TRT 3", estado: "Minas Gerais", trt: 3 },
        { nome: "TRT 4", estado: "Rio Grande do Sul", trt: 4 },
        { nome: "TRT 5", estado: "Bahia", trt: 5 },
        { nome: "TRT 6", estado: "Pernambuco", trt: 6 },
        { nome: "TRT 7", estado: "Ceará", trt: 7 },
        { nome: "TRT 8", estado: "Pará e Amapá", trt: 8 },
        { nome: "TRT 9", estado: "Paraná", trt: 9 },
        { nome: "TRT 10", estado: "Distrito Federal e Tocantins", trt: 10 },
        { nome: "TRT 11", estado: "Amazonas e Roraima", trt: 11 },
        { nome: "TRT 12", estado: "Santa Catarina", trt: 12 },
        { nome: "TRT 13", estado: "Paraíba", trt: 13 },
        { nome: "TRT 14", estado: "Rondônia e Acre", trt: 14 },
        { nome: "TRT 15", estado: "São Paulo", trt: 15 },
        { nome: "TRT 16", estado: "Maranhão", trt: 16 },
        { nome: "TRT 17", estado: "Espírito Santo", trt: 17 },
        { nome: "TRT 18", estado: "Goiás", trt: 18 },
        { nome: "TRT 19", estado: "Alagoas", trt: 19 },
        { nome: "TRT 20", estado: "Sergipe", trt: 20 },
        { nome: "TRT 21", estado: "Rio Grande do Norte", trt: 21 },
        { nome: "TRT 22", estado: "Piauí", trt: 22 },
        { nome: "TRT 23", estado: "Mato Grosso", trt: 23 },
        { nome: "TRT 24", estado: "Mato Grosso do Sul", trt: 24 },
      ];

      
      const getTRTsByState = useCallback((estado) => {
        const trt = [
            { nome: "TRT 1", estado: "Rio de Janeiro", trt: 1 },
            { nome: "TRT 2", estado: "São Paulo", trt: 2 },
            { nome: "TRT 3", estado: "Minas Gerais", trt: 3 },
            { nome: "TRT 4", estado: "Rio Grande do Sul", trt: 4 },
            { nome: "TRT 5", estado: "Bahia", trt: 5 },
            { nome: "TRT 6", estado: "Pernambuco", trt: 6 },
            { nome: "TRT 7", estado: "Ceará", trt: 7 },
            { nome: "TRT 8", estado: "Pará e Amapá", trt: 8 },
            { nome: "TRT 9", estado: "Paraná", trt: 9 },
            { nome: "TRT 10", estado: "Distrito Federal e Tocantins", trt: 10 },
            { nome: "TRT 11", estado: "Amazonas e Roraima", trt: 11 },
            { nome: "TRT 12", estado: "Santa Catarina", trt: 12 },
            { nome: "TRT 13", estado: "Paraíba", trt: 13 },
            { nome: "TRT 14", estado: "Rondônia e Acre", trt: 14 },
            { nome: "TRT 15", estado: "São Paulo", trt: 15 },
            { nome: "TRT 16", estado: "Maranhão", trt: 16 },
            { nome: "TRT 17", estado: "Espírito Santo", trt: 17 },
            { nome: "TRT 18", estado: "Goiás", trt: 18 },
            { nome: "TRT 19", estado: "Alagoas", trt: 19 },
            { nome: "TRT 20", estado: "Sergipe", trt: 20 },
            { nome: "TRT 21", estado: "Rio Grande do Norte", trt: 21 },
            { nome: "TRT 22", estado: "Piauí", trt: 22 },
            { nome: "TRT 23", estado: "Mato Grosso", trt: 23 },
            { nome: "TRT 24", estado: "Mato Grosso do Sul", trt: 24 },
        ];
    
        return trt
            .filter((item) => item.estado.includes(estado)) // Inclui todos os TRTs associados ao estado
            .map((item) => item.trt); // Retorna apenas os IDs dos TRTs
    }, []);

        // Filtrar os dados com base no estado selecionado
        useEffect(() => {
            const stateToUse = selectedState || 'São Paulo'; // Usa "São Paulo" como padrão
        
            // Filtrar os dados com base no estado selecionado
            const filteredData = geralPorEstado.temp.reduce((acc, item) => {
                // Obter todos os TRTs associados ao estado selecionado
                const selectedTRTs = getTRTsByState(stateToUse);
        
                // Verificar se o TRT do item pertence ao estado selecionado
                if (selectedTRTs.includes(parseInt(item.trt, 10))) {
                    // Procurar pelo registro no acumulador (sem considerar estado)
                    const existing = acc[0];
        
                    if (existing) {
                        existing.analise_manual += parseInt(item.analise_manual || 0, 10);
                        existing.compra += parseInt(item.compra || 0, 10);
                        existing.compra_24m_e_emprestimo_12x += parseInt(item.compra_24m_e_emprestimo_12x || 0, 10);
                        existing.compra_36m_e_emprestimo_18x += parseInt(item.compra_36m_e_emprestimo_18x || 0, 10);
                        existing.compra_imediata += parseInt(item.compra_imediata || 0, 10);
                        existing.empresa_insolvente += parseInt(item.empresa_insolvente || 0, 10);
                        existing.emprestimo_24x += parseInt(item.emprestimo_24x || 0, 10);
                        existing.erro_de_busca += parseInt(item.erro_de_busca || 0, 10);
                        existing.pendencia_processual += parseInt(item.pendencia_processual || 0, 10);
                        existing.precatorio_ou_precatocio_trabalhista += parseInt(item.precatorio_ou_precatocio_trabalhista || 0, 10);
                        existing.tese_ruim += parseInt(item.tese_ruim || 0, 10);
                        existing.valor_baixo += parseInt(item.valor_baixo || 0, 10);
                    } else {
                        // Adicionar um novo registro ao acumulador (sem incluir estado)
                        acc.push({
                            analise_manual: parseInt(item.analise_manual || 0, 10),
                            compra: parseInt(item.compra || 0, 10),
                            compra_24m_e_emprestimo_12x: parseInt(item.compra_24m_e_emprestimo_12x || 0, 10),
                            compra_36m_e_emprestimo_18x: parseInt(item.compra_36m_e_emprestimo_18x || 0, 10),
                            compra_imediata: parseInt(item.compra_imediata || 0, 10),
                            empresa_insolvente: parseInt(item.empresa_insolvente || 0, 10),
                            emprestimo_24x: parseInt(item.emprestimo_24x || 0, 10),
                            erro_de_busca: parseInt(item.erro_de_busca || 0, 10),
                            pendencia_processual: parseInt(item.pendencia_processual || 0, 10),
                            precatorio_ou_precatocio_trabalhista: parseInt(item.precatorio_ou_precatocio_trabalhista || 0, 10),
                            tese_ruim: parseInt(item.tese_ruim || 0, 10),
                            valor_baixo: parseInt(item.valor_baixo || 0, 10),
                        });
                    }
                }
        
                return acc;
            }, []);
        
            // Caso o acumulador esteja vazio, inicializar com valores 0
            if (filteredData.length === 0) {
                filteredData.push({
                    analise_manual: 0,
                    compra: 0,
                    compra_24m_e_emprestimo_12x: 0,
                    compra_36m_e_emprestimo_18x: 0,
                    compra_imediata: 0,
                    empresa_insolvente: 0,
                    emprestimo_24x: 0,
                    erro_de_busca: 0,
                    pendencia_processual: 0,
                    precatorio_ou_precatocio_trabalhista: 0,
                    tese_ruim: 0,
                    valor_baixo: 0,
                });
            }
        
            // Atualizar o estado renderGeralPorEstado com os dados filtrados
            setRenderGeralPorEstado({ temp: filteredData });
        }, [selectedState, geralPorEstado, getTRTsByState]);
        
          
    const estados = [
        { nome: 'Acre' },
        { nome: 'Alagoas' },
        { nome: 'Amapá'},
        { nome: 'Amazonas' },
        { nome: 'Bahia' },
        { nome: 'Ceará' },
        { nome: 'Distrito Federal' },
        { nome: 'Espírito Santo' },
        { nome: 'Goiás' },
        { nome: 'Maranhão' },
        { nome: 'Mato Grosso'},
        { nome: 'Mato Grosso do Sul' },
        { nome: 'Minas Gerais' },
        { nome: 'Pará'},
        { nome: 'Paraíba' },
        { nome: 'Paraná'},
        { nome: 'Pernambuco' },
        { nome: 'Piauí' },
        { nome: 'Rio de Janeiro' },
        { nome: 'Rio Grande do Norte' },
        { nome: 'Rio Grande do Sul'},
        { nome: 'Rondônia'},
        { nome: 'Roraima' },
        { nome: 'Santa Catarina' },
        { nome: 'São Paulo' },
        { nome: 'Sergipe' },
        { nome: 'Tocantins' },
    ];


    const trtsFiltrados = selectedState
    ? trts.filter((trt) => {
        if (selectedState === "Mato Grosso" || selectedState === "Mato Grosso do Sul") {
          return trt.estado === selectedState; // Comparação exata para Mato Grosso ou Mato Grosso do Sul
        } else {
          return trt.estado.includes(selectedState); // Usar includes para outros estados
        }
      })
    : trts;
  
      

      const [renderEstados, setRenderEstados] = useState(callEstado);
      const [renderEstadoValor, setRenderEstadoValor] = useState(callEstadoValor);
      const [renderInstancia, setRenderInstancia] = useState(0);

      const [rendertotalProcessos, setRenderTotalProcessos] = useState(0);
      const [renderprocessosValorTotal, setRenderProcessosValorTotal] = useState(0);
      const [renderprocessosTransitados, setRenderProcessosTransitados] = useState(0);
      const [renderprocessosDistribuido, setRenderProcessosDistribuido] = useState(0);
      const [renderprocessosArquivados, setRenderProcessosArquivados] = useState(0); 
      
      const handleStateClick = (estado) => {
        if (!estado) {
          // Caso o estado seja null ou undefined, reseta os valores
          setSelectedState(estado);
          setRenderEstados(callEstado);
          setRenderEstadoValor(callEstadoValor);
          setRenderInstancia(instancia);
      
          setRenderTotalProcessos(totalProcessos);
          setRenderProcessosValorTotal(processosValorTotal);
          setRenderProcessosTransitados(processosTransitados);
          setRenderProcessosDistribuido(processosDistribuido);
          setRenderProcessosArquivados(processosArquivados);
      
          return;
        }
      
        setSelectedState(estado);
      
        // Divida o estado em múltiplos valores, considerando que a separação seja por " e "
        const estadosSelecionados = estado.split(" e ").map((est) => est.trim());
      
        // Função para determinar a comparação correta entre '===' e 'includes'
        const compararEstado = (estado, selecionado) => {
          if (selecionado === "Mato Grosso" || selecionado === "Mato Grosso do Sul") {
            return estado.nome === selecionado; // Comparação exata para Mato Grosso ou Mato Grosso do Sul
          } else {
            return estado.nome.includes(selecionado); // Usar includes para outros estados
          }
        };
      
        // Filtra os estados usando a função de comparação
        const estadosFiltrados = callEstado.filter((est) =>
          estadosSelecionados.some((selecionado) => compararEstado(est, selecionado))
        );
      
        const estadosValorFiltrados = callEstadoValor.filter((est) =>
          estadosSelecionados.some((selecionado) => compararEstado(est, selecionado))
        );
      
        const instanciaValorFiltrados = stateInstancia.filter((est) =>
          estadosSelecionados.some((selecionado) => compararEstado(est, selecionado))
        );
      
        // Define os estados renderizados com base no filtro
        setRenderEstados(estadosFiltrados.length > 0 ? estadosFiltrados : callEstado);
        setRenderEstadoValor(estadosValorFiltrados.length > 0 ? estadosValorFiltrados : callEstadoValor);
        setRenderInstancia(instanciaValorFiltrados.length > 0 ? instanciaValorFiltrados : instancia);
      
        // Filtro para os renderprocessos
        const estadoFiltrado = dadosConsolidadosCards.find((item) =>
          estadosSelecionados.some((selecionado) => compararEstado(item, selecionado))
        );
      
        if (estadoFiltrado) {
          setRenderTotalProcessos(estadoFiltrado.total_processo);
          setRenderProcessosValorTotal(estadoFiltrado.total_valor || 0); // Adicionei `valor_total` como exemplo
          setRenderProcessosTransitados(estadoFiltrado.transitados);
          setRenderProcessosDistribuido(estadoFiltrado.distribuido);
          setRenderProcessosArquivados(estadoFiltrado.arquivados);
        } else {
          // Caso nenhum estado seja encontrado, reseta os valores
          setRenderTotalProcessos(0);
          setRenderProcessosValorTotal(0);
          setRenderProcessosTransitados(0);
          setRenderProcessosDistribuido(0);
          setRenderProcessosArquivados(0);
        }
      };   
      
      // UseEffect para inicializar os estados quando `callEstado` ou `callEstadoValor` forem carregados
      useEffect(() => {
        // Aqui você pode garantir que o estado inicial só é setado quando os dados estiverem prontos
        if (callEstado.length > 0 && callEstadoValor.length > 0) {
          setRenderEstados(callEstado);
          setRenderEstadoValor(callEstadoValor);
        }
      }, [callEstado, callEstadoValor]);
    

    const handleStatusChange = (event) => {
        const estadoSelecionado = event.target.value;

        // Encontre o estado no array `trt`
        const estadoRelacionado = trt.find((item) => 
            item.estado.includes(estadoSelecionado) && item.estado.includes(" e ")
        );
    
        // Se o estado tiver uma relação, use o nome completo com os dois estados
        const estadosParaEnviar = estadoRelacionado ? estadoRelacionado.estado : estadoSelecionado;
    
        handleStateClick(estadosParaEnviar);
        
    }


    const formatInput = (value) => {
        const onlyNumbers = value.replace(/\D/g, '');
        let formattedValue = value;
    
        if (value.startsWith('(')) {
            if (onlyNumbers.length === 11) {
                // Telefone celular
                formattedValue = onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            } else if (onlyNumbers.length === 10) {
                // Telefone fixo
                formattedValue = onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            }
        } else if (onlyNumbers.length === 11) {
            // CPF
            formattedValue = onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (onlyNumbers.length === 14) {
            // CNPJ
            formattedValue = onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else if (onlyNumbers.length === 20) {
            // Processo
            formattedValue = onlyNumbers.replace(/(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/, '$1-$2.$3.$4.$5.$6');
        }
    
        return formattedValue;
    };
    
    // Regex Patterns
    const patterns = {
        trabalhista: /^\d{7}-\d{2}\.\d{4}\.5\.\d{2}\.\d{4}$/,
        precatorio: /^\d{7}-\d{2}\.\d{4}\.8\.\d{2}\.\d{4}$/,
        cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{11}$/,
        cnpj: /^\d{14}$|(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2})/,
        email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        telefone: /^\(\d{2}\)\s?\d{4,5}-\d{4}$/
    };
    
    // Função para determinar o tipo de busca
    const determineSearchType = (value) => {
        if (patterns.trabalhista.test(value)) return 'trabalhista';
        if (patterns.precatorio.test(value)) return 'precatorio';
        if (patterns.cpf.test(value)) return 'cpf';
        if (patterns.cnpj.test(value)) return 'cnpj';
        if (patterns.email.test(value)) return 'email';
        if (patterns.telefone.test(value)) return 'telephone';
        return 'nome'; // Presume nome se não encaixar nas categorias acima
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const trimmedSearch = search.trim();
        if (!trimmedSearch) return;

        onNavLinkClick("Consultas")
        const type = determineSearchType(trimmedSearch);
        const cleanSearch = type === 'telephone' ? formatInput(trimmedSearch).replace(/[^\d]/g, '') : trimmedSearch;

        if (type === 'cpf' || type === 'cnpj' || type === 'email' || type === 'telephone'|| type === 'nome') {
            handleCPFSearch(cleanSearch, type);
        } else {
            handleSearch(cleanSearch, type);
        }
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const handleStateSelect = (estado) => {
        const estadoSelecionado = estado;

        // Encontre o estado no array `trt`
        const estadoRelacionado = trt.find((item) => 
            item.estado.includes(estadoSelecionado) && item.estado.includes(" e ")
        );
    
        // Se o estado tiver uma relação, use o nome completo com os dois estados
        const estadosParaEnviar = estadoRelacionado ? estadoRelacionado.estado : estadoSelecionado;
    
        handleStateClick(estadosParaEnviar);
        setIsDropdownOpen(false); 
    };
   
    return (
        <div>
        <C.SearchContainer onSubmit={onSubmit}>
            <C.InputBody onClick={handleSearchClick}>
                <BsSearch />
                <Input
                    type="text"
                    placeholder="Busca por CPF, nome, telefone com ( ), email, processo, CNPJ ou empresa"
                    id="input_search"
                    autocomplete="off"
                    value={search}
                    onChange={(e) => setSearch(formatInput(e.target.value))}
                />
            </C.InputBody>
            <Button Text="Consultar" type="submit" />
        </C.SearchContainer>
        <C.RelSection>

            <C.Grafics>
            <C.Cardscontainer>
                <C.DivControlerMaps>
                    <C.Controler>
                        <h1>Processos Trabalhistas</h1>
                        <p>Dados consolidados</p>
                    </C.Controler>
                    <C.ControlerFilter>
                        <div onClick={() => handleStateSelect(null)}>
                            <MdDeleteOutline />
                                Limpar
                        </div>
                    </C.ControlerFilter>
                </C.DivControlerMaps>
                <C.DashboardGrid>
                    <Cards title="Total de Processos" value={rendertotalProcessos} color="#dce3f5" icon={<SiGoogledocs />} pincel="#655cf5"/>
                    <Cards title="Valor total dos processos" value={`R$${renderprocessosValorTotal}`} color="#edd3d5" icon={<FaUserPlus/>} pincel="#fb8085"/>
                    <Cards title="Valor médio dos processos" value={`R$${renderprocessosValorTotal / rendertotalProcessos}`} color="#ffedcc" icon={<PiPencilSimpleLight />} pincel="#fbc764"/> 
                    <Cards title="Distribuidos nos últimos 30 dias" value={renderprocessosDistribuido} color="#dce3f5" icon={<SiGoogledocs />} pincel="#655cf5"/>
                    <Cards title="Arquivados nos últimos 30 dias" value={renderprocessosArquivados} color="#edd3d5" icon={<FaUserPlus/>} pincel="#fb8085"/>
                    <Cards title="Transitados em julgados nos últimos 30 dias" value={renderprocessosTransitados} color="#ffedcc" icon={<PiPencilSimpleLight />} pincel="#fbc764"/> 
                </C.DashboardGrid>
            </C.Cardscontainer>
                <C.Maps>
                <C.DivControlerMaps>
                    <C.Controler>
                        <h1>Mapa de processos ativos</h1>
                        <p>com valor total das causas</p>
                    </C.Controler>
                    <C.ControlerFilter>
                        <div onClick={toggleDropdown}>
                            <MdFilterList />
                            Filtro
                        </div>
                        {isDropdownOpen && (
                            <C.Dropdown>
                                {[
                                    { nome: "Limpar", valor: null }, 
                                    ...estados, 
                                    ].map((estado) => (
                                    <C.DropdownItem
                                        key={estado.nome}
                                        $isHighlight={estado.nome === "Limpar"}
                                        onClick={() => {
                                            if (estado.nome === "Limpar") {
                                                handleStateSelect(null); 
                                            } else {
                                                handleStateSelect(estado.nome);
                                            }
                                        }}
                                    >
                                        {estado.nome}
                                    </C.DropdownItem>
                                ))}
                            </C.Dropdown>
                        )}
                    </C.ControlerFilter>
                </C.DivControlerMaps>
                    <MapChart estados={callEstado} onClickState={handleStateClick} selectedState={selectedState} setSelectedState={setSelectedState}/>
                </C.Maps>
            </C.Grafics>

            <C.GraficsContainer>
                    <div>
                            <h4>Processos ativos por estado</h4>
                        <C.GraficsItem>
                            <GraficsList 
                                estados={renderEstados}
                                estadosPorPagina={5}
                                onClickState={handleStateClick} 
                                selectedState={selectedState} 
                                setSelectedState={setSelectedState}
                            />
                        </C.GraficsItem>
                    </div>
                    <div>
                            <h4>Processos ativos por comarca</h4>
                        <C.GraficsItem>
                            <GraficsList 
                                estados={trtsFiltrados}
                                estadosPorPagina={5}
                                onClickTRT={handleStateClick} 
                                selectedState={selectedState} 
                                setSelectedState={setSelectedState}
                            />
                        </C.GraficsItem>
                    </div>
            </C.GraficsContainer>

            <C.ContainerMidBarChart>
                <div>
                    <h4>Processos ativos por fase</h4>
                    <FaseBarChart instancias = {renderInstancia}/>
                </div>
                <div>
                    <h4>Valor de Causa (em R$)</h4>
                    <GraficsList 
                        estados={renderEstadoValor}
                        estadosPorPagina={5}
                        onClickState={handleStateClick} 
                        selectedState={selectedState} 
                        setSelectedState={setSelectedState}
                    />
                </div>
            </C.ContainerMidBarChart>

            <C.ContainerBarChart>
                <h4>Duração dos processos por estado (em dias)</h4>
                <EstadoBarChart handleStateClick={handleStateClick} duracao = {duracao}/>
            </C.ContainerBarChart>

            {tableMathData.length>0 && (<TableComponent tableMathData={tableMathData}/>)}

            <LineChartComponent
            dataYearlyTrabalhista={dataYearlyTrabalhista}
            dataMonthlyTrabalhista={dataMonthlyTrabalhista}
            dataYearlyPrecatorio={dataYearlyPrecatorio}
            dataMonthlyPrecatorio={dataMonthlyPrecatorio}
            />

            <C.ContainerAside>
            <C.ContainerStatusMetrix>
                    <C.Title>
                        <h4 onClick={() => handleOrdenarClick('Processos por Status')}>Processos por Status</h4>
                    </C.Title>
                    <OutGraficsList 
                            data={processDropTotal}
                            estadosPorPagina={13}
                            ordenar={ordenar['Processos por Status'] || false}
                    />
                </C.ContainerStatusMetrix>
                
                <C.ContainerStatusMetrix>
                    <C.SelectContainer>
                        <h4 onClick={() => handleOrdenarClick('Status por Estado')}>Status por Estado</h4>
                        <C.Select onChange={handleStatusChange}  name="statusPorEstado" value={selectedState ? selectedState.split(" e ")[0] : 'São Paulo'}>
                            {estados.map((estado) => (
                                <C.Option key={estado.nome} value={estado.nome}>
                                {estado.nome}
                                </C.Option>
                            ))}
                        </C.Select>
                    </C.SelectContainer>
                    <OutGraficsList  
                            data={renderGeralPorEstado}
                            estadosPorPagina={12}
                            ordenar={ordenar['Status por Estado'] || false}
                    /> 
                </C.ContainerStatusMetrix>
                <C.ContainerStatusMetrix>
                    <C.Title>
                        <h4 onClick={() => handleOrdenarClick('processos30D')}>Status Últimos 30 dias</h4>
                    </C.Title>
                    <OutGraficsList  
                            data={processos30D}
                            estadosPorPagina={13}
                            ordenar={ordenar['processos30D'] || false}
                    />
                </C.ContainerStatusMetrix>

            </C.ContainerAside>

        </C.RelSection>

        </div>
    );
};

export default Relatorios;