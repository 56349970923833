import React from 'react';
import * as C from './GraficoBarStyle'; // Importe seus componentes estilizados aqui

const OutGraficsList = ({ data, estadosPorPagina, ordenar }) => {
    const [currentPage, setCurrentPage] = React.useState(1);

    const nomeCorretoMap = {
        compra_imediata: "Compra Imediata",
        compra_24m_e_emprestimo_12x: "Compra 24m e Empréstimo 12x",
        compra_36m_e_emprestimo_18x: "Compra 36m e Empréstimo 18x",
        emprestimo_24x: "Empréstimo 24x",
        analise_manual: "Análise Manual",
        empresa_insolvente: "Empresa Insolvente",
        pendencia_processual: "Pendência Processual",
        tese_ruim: "Tese Ruim",
        valor_baixo: "Valor Baixo",
        erro_de_busca: "Erro de Busca",
        precatorio_ou_precatorio_trabalhista: "Precatório",
        precatorio_ou_precatocio_trabalhista: "Precatório",
        desconhecido: "Desconhecido",
    };

    const estados = data?.temp?.[0]
        ? Object.entries(data?.temp?.[0]).map(([nome, valor]) => ({
            nome,
            valor: parseFloat(valor),
        }))
        : [];

    const estadosFiltrados = estados.filter(
        (estado) => estado.nome.toLowerCase() !== "desconhecido" && estado.nome.toLowerCase() !== "compra"
    );

    // Ordenação condicional com base no sinal do componente pai
    if (ordenar) {
        // Ordenar pelo valor
        estadosFiltrados.sort((a, b) => b.valor - a.valor); // Ordem decrescente
    } else {
        // Ordenar pelo nome usando a ordem definida em nomeCorretoMap
        const ordemChaves = Object.keys(nomeCorretoMap);
        estadosFiltrados.sort((a, b) => ordemChaves.indexOf(a.nome) - ordemChaves.indexOf(b.nome));
    }

    const formatNome = (nome) =>
        nomeCorretoMap[nome] ||
        nome
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/(^|\s)\S/g, (match) => match.toUpperCase());

    const totalProcessosFull = estadosFiltrados.reduce((sum, estado) => sum + estado.valor, 0);

    const paginasTotais = Math.ceil(estadosFiltrados.length / estadosPorPagina);
    const indiceUltimoEstado = currentPage * estadosPorPagina;
    const indicePrimeiroEstado = indiceUltimoEstado - estadosPorPagina;
    const estadosAtuais = estadosFiltrados.slice(indicePrimeiroEstado, indiceUltimoEstado);

    const getBarColor = (nome) => {
        const verdeStatus = ['compra_imediata', 'compra', 'compra_36m_e_emprestimo_18x', 'compra_24m_e_emprestimo_12x', 'emprestimo_24x'];
        const vermelhoStatus = ['pendencia_processual', 'tese_ruim', 'valor_baixo', 'erro_de_busca'];
        const amareloStatus = ['analise_manual', 'empresa_insolvente'];
        const azulStatus = ['precatorio_ou_precatorio_trabalhista', 'precatorio_ou_precatocio_trabalhista'];

        if (verdeStatus.includes(nome)) return '#4caf50';
        if (vermelhoStatus.includes(nome)) return '#f44336';
        if (amareloStatus.includes(nome)) return '#fbc764';
        if (azulStatus.includes(nome)) return '#8884d8';
        return '#655cf5';
    };

    if (!data || estados.length === 0) {
        return <div>Nenhum dado disponível</div>;
    }

    return (
        <C.BarEstado>
            {estadosAtuais.map((estado) => {
                const porcentagem = (estado.valor / totalProcessosFull) * 100;
                const cor = getBarColor(estado.nome);

                return (
                    <C.BarContainer key={estado.nome}>
                        <C.BarTitle>
                            <C.BarLabel>{formatNome(estado.nome)}</C.BarLabel>
                            <C.BarValue>{estado.valor.toLocaleString('pt-BR')}</C.BarValue>
                        </C.BarTitle>
                        <C.ProgressBar>
                            <C.Bar>
                                <C.Progress $valor={porcentagem} $cor={cor} />
                            </C.Bar>
                        </C.ProgressBar>
                    </C.BarContainer>
                );
            })}

            {paginasTotais > 1 && (
                <C.PaginationContainer>
                    {Array.from({ length: paginasTotais }, (_, index) => (
                        <C.PaginationDot
                            key={index + 1}
                            $isActive={currentPage === index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                        />
                    ))}
                </C.PaginationContainer>
            )}
        </C.BarEstado>
    );
};

export default OutGraficsList;
