import React from "react";
import * as C from "./CardsStyles";
import "../../styles/variables.css";

const formatValue = (value) => {
    // Verifica se o valor é uma string e contém "%"
    const isPercentage = typeof value === 'string' && value.includes('%');

    // Se for um valor percentual, retorna o valor formatado com "%"
    if (isPercentage) {
        // Remove o símbolo "%" e converte para número
        const numericValue = parseFloat(value.replace('%', ''));
        return `${numericValue.toLocaleString('pt-BR')}%`; // Formata o número e adiciona "%"
    }

    // Verifica se a entrada é uma string com "R$"
    const isCurrency = typeof value === 'string' && value.includes('R$');

    // Remove o "R$" e outros caracteres não numéricos e converte para número
    const numericValue = isCurrency 
        ? parseFloat(value.replace(/[R$\s,]/g, '')).toFixed(2) // Arredonda para 2 casas decimais
        : value;

    let formattedValue = '';

    const formatWithDecimals = (num) => {
        // Formata o número com 2 casas decimais, substituindo o ponto por vírgula
        let formatted = num.toFixed(2).replace('.', ',');  
        if (formatted.endsWith(',00')) {
            formatted = formatted.slice(0, -3);  // Remove ",00" caso não seja necessário
        } else if (formatted.endsWith('0')) {
            formatted = formatted.slice(0, -1); // Remove o zero se for a única casa após a vírgula
        }
        return formatted;
    };

    // Para grandes números, formata conforme a magnitude (milhão, bilhão, etc.)
    if (numericValue >= 1e18) {
        formattedValue = formatWithDecimals(numericValue / 1e18) + ' Qi'; // Quintilhão
    } else if (numericValue >= 1e15) {
        formattedValue = formatWithDecimals(numericValue / 1e15) + ' Qa'; // Quadrilhão
    } else if (numericValue >= 1e12) {
        formattedValue = formatWithDecimals(numericValue / 1e12) + ' Tri'; // Trilhão
    } else if (numericValue >= 1e9) {
        formattedValue = formatWithDecimals(numericValue / 1e9) + ' Bi'; // Bilhão
    } else if (numericValue >= 1e6) {
        formattedValue = formatWithDecimals(numericValue / 1e6) + ' M'; // Milhão
    } else {
        // Para valores menores, usa toLocaleString com pt-BR
        formattedValue = parseFloat(numericValue).toLocaleString('pt-BR');
    }

    // Se for moeda, adiciona o "R$"
    return isCurrency ? `R$ ${formattedValue}` : formattedValue;
};

const Cards = ({ title, value, color, icon, pincel }) => {
    return (
        <C.Card color={color}>
            <C.Focus>
                <C.CardBubble color={pincel}><p>{icon}</p></C.CardBubble>
                <C.CardValue>{formatValue(value)}</C.CardValue>
            </C.Focus>
            <C.CardTitle>{title}</C.CardTitle>
        </C.Card>
    );
};

export default Cards;