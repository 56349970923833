import React, { useState } from "react";
import  * as C from "./TableComponentstyle"

const TableComponent = ({tableMathData}) => {
  const [viewMode, setViewMode] = useState("monthly");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const availableYears = [...new Set(tableMathData.map((item) => item.year))];

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  return (
<C.TableWrapper>
  <C.ButtonContainer>
    {viewMode === "monthly" && (
      <C.Select onChange={handleYearChange} value={selectedYear} name="yearselect">
        {availableYears.map((year) => (
          <C.Option key={year} value={year}>
            {year}
          </C.Option>
        ))}
      </C.Select>
    )}
    <C.Button onClick={() => setViewMode("monthly")}>Mensal</C.Button>
    <C.Button onClick={() => setViewMode("yearly")}>Anual</C.Button>
  </C.ButtonContainer>

  <C.Envelopes>
    <C.ScrollableTable>
      <thead>
        <tr>
          <C.TableHeader>Descrição</C.TableHeader>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => <C.TableHeader key={idx}>{month.month}</C.TableHeader>)
            : availableYears.map((year) => <C.TableHeader key={year}>{year}</C.TableHeader>)}
        </tr>
      </thead>
      <tbody>
        <tr>
          <C.TableCell>Total dos processos</C.TableCell>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => (
                  <C.TableCell key={idx}>
                    {month.totalProcesses.toLocaleString("pt-BR")}
                  </C.TableCell>
                ))
            : availableYears.map((year) => (
                <C.TableCell key={year}>
                  {tableMathData
                    .filter((item) => item.year === year)
                    .reduce((sum, item) => sum + item.totalProcesses, 0)
                    .toLocaleString("pt-BR")}
                </C.TableCell>
              ))}
        </tr>
        <tr>
          <C.TableCell>Valor total dos processos</C.TableCell>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => (
                  <C.TableCell key={idx}>
                    {Number(month.totalValue).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  </C.TableCell>
                ))
            : availableYears.map((year) => {
                const totalYearValue = tableMathData
                  .filter((item) => item.year === year)
                  .reduce((sum, item) => sum + Number(item.totalValue), 0);

                return (
                  <C.TableCell key={year}>
                    {totalYearValue.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  </C.TableCell>
                );
              })}
        </tr>
        <tr>
          <C.TableCell>Valor médio dos processos</C.TableCell>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => {
                  const valorMedio = month.totalProcesses > 0 
                    ? Number(month.totalValue) / month.totalProcesses 
                    : 0;
                  return (
                    <C.TableCell key={idx}>
                      {valorMedio.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                    </C.TableCell>
                  );
                })
            : availableYears.map((year) => {
                const filteredData = tableMathData.filter((item) => item.year === year);
                const totalYearValue = filteredData.reduce((sum, item) => sum + Number(item.totalValue), 0);
                const totalYearProcesses = filteredData.reduce((sum, item) => sum + item.totalProcesses, 0);
                const valorMedioAnual = totalYearProcesses > 0 
                  ? totalYearValue / totalYearProcesses 
                  : 0;

                return (
                  <C.TableCell key={year}>
                    {valorMedioAnual.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  </C.TableCell>
                );
              })}
        </tr>
        <tr>
          <C.TableCell>Distribuidos</C.TableCell>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => (
                  <C.TableCell key={idx}>
                    {month.archived.toLocaleString("pt-BR")}
                  </C.TableCell>
                ))
            : availableYears.map((year) => (
                <C.TableCell key={year}>
                  {tableMathData
                    .filter((item) => item.year === year)
                    .reduce((sum, item) => sum + item.archived, 0)
                    .toLocaleString("pt-BR")}
                </C.TableCell>
              ))}
        </tr>
        <tr>
          <C.TableCell>Arquivados</C.TableCell>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => (
                  <C.TableCell key={idx}>
                    {month.judged.toLocaleString("pt-BR")}
                  </C.TableCell>
                ))
            : availableYears.map((year) => (
                <C.TableCell key={year}>
                  {tableMathData
                    .filter((item) => item.year === year)
                    .reduce((sum, item) => sum + item.judged, 0)
                    .toLocaleString("pt-BR")}
                </C.TableCell>
              ))}
        </tr>
        <tr>
          <C.TableCell>Transitados em julgados</C.TableCell>
          {viewMode === "monthly"
            ? tableMathData
                .filter((item) => item.year === selectedYear)
                .map((month, idx) => (
                  <C.TableCell key={idx}>
                    {month.judged.toLocaleString("pt-BR")}
                  </C.TableCell>
                ))
            : availableYears.map((year) => (
                <C.TableCell key={year}>
                  {tableMathData
                    .filter((item) => item.year === year)
                    .reduce((sum, item) => sum + item.judged, 0)
                    .toLocaleString("pt-BR")}
                </C.TableCell>
              ))}
        </tr>
      </tbody>
    </C.ScrollableTable>
  </C.Envelopes>
</C.TableWrapper>
  );
};

export default TableComponent;